<template>
  <v-card>
    <v-app>
      <v-navigation-drawer permanent :width="68">
        <PrimarySidebar />
      </v-navigation-drawer>
      <v-main>
        <v-overlay :model-value="loading" class="align-center justify-center" persistent>
          <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <CustomAlert></CustomAlert>
        <CustomSnackbar></CustomSnackbar>
        <router-view />
      </v-main>
    </v-app>
  </v-card>
</template>

<script lang="ts" setup>
import PrimarySidebar from '@/components/ui/sidebar/PrimarySidebar.vue'
import TheSidebars from '@/components/ui/sidebar/TheSidebars.vue'
import CustomAlert from '@/components/ui/alerts/CustomAlert.vue'
import CustomSnackbar from '@/components/ui/alerts/CustomSnackbar.vue'
import { useAppStore } from '@/store/app'
import { storeToRefs } from 'pinia'

const appStore = useAppStore()
const { loading } = storeToRefs(appStore)
</script>
