<template>
  <div ref="target" class="c-status-button" @click="toogle()" :class="{ active: statusOnOff }">
    <div class="c-status-button-current">
      <span class="c-status-button-indecator" :style="colorClass"></span>
      {{ $t(String(currentValue)) }}
      <IconBase v-if="!props.disabled" name="Arrowdown" customClass="c-status-button-arrow" width="9" height="9" />
    </div>
    <div class="c-status-button-list">
      <template v-for="item in options" :key="item.id">
        <div class="c-status-button-item" @click="setStatus(item.value as number)">
          <span class="c-status-button-indecator" :style="getColorClass(item.value as number)"></span>
          <span class="c-status-button-val">{{ item.title }}</span>
          <IconBase v-if="item.value == props.status" name="Markcheck" customClass="d-inline" width="9" height="9" />
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import IconBase from '@/components/icons/IconBase.vue'
import { PropType, computed, ref } from 'vue'
import { ButtonStatusProps } from '@/types/button/Buttonstatus.types'
import { onClickOutside } from '@vueuse/core'
import { useAppStore } from '@/store/app'
import { RestApi, PageCase } from '@/imports'
import { useLocale } from 'vuetify'

const appStore = useAppStore()
const { t } = useLocale()
let message = t('try_again_later')

interface Option {
  title: string
  value: number | string
}

const props = defineProps({
  id: {
    type: Number as PropType<ButtonStatusProps['id']>,
    required: true
  },
  options: {
    type: Array as () => Option[],
    required: true
  },
  type: {
    type: String
  },
  status: {
    type: Number
  },
  disabled: {
    type: Boolean,
    defaulet: false
  }
})
const emits = defineEmits(['editstatus', 'update'])

const target = ref(null)

const data = ref({
  status: 2
})

const statusOnOff = ref(false)

const colorClass = computed(() => {
  if (props.type == 'coupon') {
    switch (props.status) {
      case 2:
        return 'background-color: #cf3737;'
      case 1:
        return 'background-color: #26AD75;'
      case 0:
        return 'background-color: #175CD3;'
      default:
        return ''
    }
  } else if (props.type == 'template') {
    switch (props.status) {
      case 2:
        return 'background-color: #cf3737;'
      case 1:
        return 'background-color: #26AD75;'
      case 0:
        return 'background-color: #175CD3;'
      default:
        return ''
    }
  } else {
    switch (props.status) {
      case 20:
        // TODO notifications - stopped
        return 'background-color: black;'
      case 12:
        // TODO notifications - processing
        return 'background-color: black;'
      case 11:
        // TODO notifications - sending
        return 'background-color: black;'
      case 10:
        // TODO notifications - sending
        return 'background-color: #cf3737;'
      case 6:
        return 'background-color: #dda231;'
      case 5:
        return 'background-color: #26AD75;'
      case 4:
        return 'background-color: #cf3737;'
      case 3:
        return 'background-color: #dda231;'
      case 2:
        return 'background-color: #26AD75;'
      case 1:
        return 'background-color: #26AD75;'
      case 0:
        return 'background-color: #cf3737;'

      default:
        return ''
    }
  }
})

const currentValue = computed(() => {
  let temp
  props.options.forEach(element => {
    if (element.value == props.status) {
      temp = element.title
    }
  })
  return temp
})

function toogle() {
  if (!props.disabled) {
    statusOnOff.value = !statusOnOff.value
  }
}

function getColorClass(item: number) {
  switch (item) {
    case 20:
      // TODO notifications - stopped
      return 'background-color: black;'
    case 12:
      // TODO notifications - processing
      return 'background-color: black;'
    case 11:
      // TODO notifications - sending
      return 'background-color: black;'
    case 4:
      // TODO expired
      return 'background-color: black;'
    case 3:
      // TODO scheduled
      return 'background-color: #DDA231;'
    case 2:
      // draft
      return 'background-color: #DDA231;'
    case 1:
      // published
      return 'background-color: #26AD75;'
    case 0:
      // archived
      return 'background-color: #175CD3;'

    default:
      return ''
  }
}

function setStatus(item: number) {
  data.value.status = item
  emits('editstatus', {
    action: item,
    id: props.id
  })
  save()
}

async function save() {
  appStore.toggleLoading(true)
  let Id = Number(props.id)
  let serverResponse

  switch (props.type) {
    case PageCase.EVENTS:
      serverResponse = await RestApi.changeStatusEvent(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.NEWS:
      serverResponse = await RestApi.changeStatusNews(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.NOTIFICATIONS:
      serverResponse = await RestApi.changeStatusNotifications(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.OFFERS:
      serverResponse = await RestApi.changeStatusOffer(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.REWARDS:
      serverResponse = await RestApi.changeStatusRewards(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.COUPONS:
      serverResponse = await RestApi.changeStatusVoucherGroups(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.COUPON:
      serverResponse = await RestApi.changeStatusVouchers(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    case PageCase.TENANT:
      serverResponse = await RestApi.setStatusTenant(Id, data.value)
      if (serverResponse.data.status === 'success') {
        emits('update')
        appStore.toggleLoading(false)
      } else {
        appStore.toggleLoading(false)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: message
        })
      }
      break

    default:
      return ''
  }
}

onClickOutside(target, () => (statusOnOff.value = false))
</script>
