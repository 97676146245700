export type Option = {
  title: string
  value: number
}

interface OptionWithCssClass {
  [key: string]: { className: string; title: string }
}

export enum availableStatus {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
  DELETED = 'deleted',
  FRAUD = 'fraud'
}

type ReceiptStatus = {
  title: string
  value: number[]
}

export const labelTemplatesStatus = ['open', 'labeled', 'checked', 'archived']

export const receiptsStatus: ReceiptStatus[] = [
  { title: 'in_review', value: [3, 6] },
  { title: 'approved', value: [1, 5] },
  { title: 'declined', value: [2, 4] }
]
export const receiptsStatusList: Option[] = [
  { title: 'in_review', value: 3 },
  { title: 'in_review', value: 6 },
  { title: 'approved', value: 1 },
  { title: 'approved', value: 5 },
  { title: 'declined', value: 2 },
  { title: 'declined', value: 4 }
]
export const receiptsRealStatus: Option[] = [
  { title: 'in_review', value: 0 },
  { title: 'approved', value: 1 },
  { title: 'in_review', value: 3 },
  { title: 'declined', value: 2 },
  { title: 'declined', value: 4 }
]
export const receiptsDetailStatus: Option[] = [
  { title: 'in_review', value: 3 },
  { title: 'approved', value: 1 },
  { title: 'declined', value: 4 }
]
export const tagsStatus: Option[] = [
  { title: availableStatus.ACTIVE, value: 1 },
  { title: availableStatus.NOT_ACTIVE, value: 0 }
]
export const customerStatus: Option[] = [
  { title: availableStatus.DELETED, value: -1 },
  { title: availableStatus.ACTIVE, value: 1 },
  { title: availableStatus.NOT_ACTIVE, value: 0 },
  { title: availableStatus.FRAUD, value: 20 }
]
export const tenantStatus: Option[] = [
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'archived', value: 0 }
]
export const calendarEventStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'expired', value: 10 }
]

export const newsTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'expired', value: 10 }
]

export const eventTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'expired', value: 10 }
]

export const saleTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'expired', value: 10 }
]

export const rewardTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'expired', value: 10 }
]

export const rewardRedeemedTypeStatus: Option[] = [
  { title: 'activated', value: 0 },
  { title: 'redeemed', value: 1 },
  { title: 'canceled', value: 2 },
  { title: 'activated_unlimit', value: 3 },
  { title: 'activated_termless', value: 4 },
  { title: 'activated_termless_unlimit', value: 5 },
  { title: 'expired', value: 10 }
]

export const typeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'in approval', value: 4 },
  { title: 'approved', value: 5 },
  { title: 'rejected', value: 6 },
  { title: 'expired', value: 10 }
]

export const couponTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 },
  { title: 'redeemed', value: 2 }
]
export const couponRedeemedTypeStatus: Option[] = [
  { title: 'rejected', value: 1 },
  { title: 'activated', value: 2 }
]
export const pushTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'sent', value: 1 },
  { title: 'draft', value: 2 },
  { title: 'scheduled', value: 3 },
  { title: 'sending', value: 11 },
  { title: 'processing', value: 12 },
  { title: 'stopped', value: 20 }
]
export const tenantTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 }
]
export const amenitiesTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 }
]

export const blogTypeStatus: Option[] = [
  { title: 'draft', value: 0 },
  { title: 'published', value: 1 },
  { title: 'scheduled', value: 2 },
  { title: 'archived', value: 3 }
]

export const storiesStatus: Option[] = [
  { title: 'draft', value: 0 },
  { title: 'published', value: 1 },
  { title: 'scheduled', value: 2 },
  { title: 'archived', value: 3 },
  { title: 'expired', value: 4 }
]


export const galleryTypeStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'published', value: 1 }
]

export const inAppMessageTypeStatus: Option[] = [
  {
    title: 'draft',
    value: 0
  },
  {
    title: 'published',
    value: 1
  },
  {
    title: 'scheduled',
    value: 2
  },
  {
    title: 'archived',
    value: 3
  }
]

export const SMSMessageTypeStatus: Option[] = [
  {
    title: 'draft',
    value: 0
  },
  {
    title: 'published',
    value: 1
  },
  {
    title: 'scheduled',
    value: 2
  },
  {
    title: 'archived',
    value: 3
  }
]

export const calendarStatus = [
  { name: 'archived', value: true, count: 0 },
  { name: 'draft', value: true, count: 0 },
  { name: 'scheduled', value: true, count: 0 },
  { name: 'published', value: true, count: 0 },
  { name: 'in_approval', value: true, count: 0 },
  { name: 'approved', value: true, count: 0 }
]

export const exportStatus: OptionWithCssClass = {
  '1': { className: 'warning', title: 'pending' },
  '2': { className: 'success', title: 'success' },
  '3': { className: 'danger', title: 'fail' }
}

// Booking

export const bookingServicesStatus: Option[] = [
  { title: 'active', value: 1 },
  { title: 'not_active', value: 2 },
  { title: 'archived', value: 0 }
]

export const bookingOptionsStatus: Option[] = [
  { title: 'active', value: 1 },
  { title: 'not_active', value: 2 },
  { title: 'archived', value: 0 }
]

export const bookingOrdersStatus: Option[] = [
  { title: 'archived', value: 0 },
  { title: 'new', value: 1 },
  { title: 'pending', value: 2 },
  { title: 'completed', value: 3 }
]
