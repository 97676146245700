export const useTransformSupportedLanguages = (payload: any[]): any[] => {
  let result: any[] = payload || []
  if (payload && payload.length) {
    result = payload
      .filter(i => i?.order || i?.locale)
      .sort((a, b) => {
        if (a.order !== null && b.order !== null) {
          return a.order - b.order
        }

        if (b.order === null) {
          return -1
        }

        if (a.order === b.order) {
          return 0
        }

        return 0
      })
      .map((i, index) => ({ ...i, order: i.order || index + 1 }))
      .sort((a: any, b: any) => a.order - b.order)
      .map((i, index) => ({ ...i, order: index + 1 }))
      .splice(0, 4)
  }
  return result
}
