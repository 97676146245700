export enum Routes {
  ASSETS_FLOORPLAN_UNIT_EDIT = '/assets/floorplan/units/edit',
  ASSETS_FLORPLAN_FLOORPLANS = '/assets/floorplan/floorplans',
  ASSETS_FLORPLAN_UNITS = '/assets/floorplan/units',
  ASSETS_FLORPLAN_CREATE_NEW_UNIT = '/assets/floorplan-create-new-unit',
  ASSETS_FLORPLAN_BUILDER = '/assets/floorplan-builder',
  ASSETS_FLORPLAN_MALL = '/assets/mall',
  LEASE = '/lease',
  LEASE_TENANTS = '/lease/tenants',
  LEASE_TENANT_NEW = '/lease/newtenant',
  LEASE_TENANT_EDIT = '/lease/edittenant/',
  LEASE_ORGANIZATIONS = '/lease/organizations',
  LEASE_ORGANIZATIONS_NEW = '/lease/organizations-new',
  LEASE_ORGANIZATIONS_EDIT = '/lease/organizations-edit/',
  LEASE_AMENITIES = '/lease/amenities',
  LEASE_AMENITIES_NEW = '/lease/create-amenities',
  LEASE_AMENITIES_EDIT = '/lease/edit-amenities/',
  BOOKING_SERVICES = '/lease/booking/services',
  BOOKING_SERVICES_NEW = '/lease/booking/services/new',
  BOOKING_SERVICES_EDIT = '/lease/booking/services/edit',
  BOOKING_OPTIONS = '/lease/booking/options',
  BOOKING_OPTIONS_NEW = '/lease/booking/options/new',
  BOOKING_OPTIONS_EDIT = '/lease/booking/options/edit',
  BOOKING_ORDERS = '/lease/booking/orders',
  BOOKING_ORDERS_NEW = '/lease/booking/orders/new',
  BOOKING_ORDERS_EDIT = '/lease/booking/orders/edit',
  LOGIN = '/login',
  INVITATION_LOGIN = '/login/invitation',
  LOYALTY = '/loyalty',
  LOYALTY_DASHBOARD = '/loyalty/dashboard',
  LOYALTY_AWARDS = '/loyalty/awards',
  LOYALTY_COUPON = '/loyalty/coupon/',
  LOYALTY_COUPONS = '/loyalty/coupons',
  LOYALTY_COUPONS_NEW = '/loyalty/newcoupons',
  LOYALTY_COUPONS_EDIT = '/loyalty/editcoupons/',
  LOYALTY_RECEIPTS = '/loyalty/receipts',
  LOYALTY_RECEIPT = '/loyalty/receipt/',
  LOYALTY_RECEIPTS_LABELER = '/loyalty/receipts/labeler/',
  LOYALTY_REWARDS = '/loyalty/rewards',
  LOYALTY_REWARDS_EDIT = '/loyalty/editreward/',
  LOYALTY_REWARDS_TIER_NEW = '/loyalty/create-rewards-tier',
  LOYALTY_REWARDS_TIER_EDIT = '/loyalty/rewards-tier/',
  LOYALTY_REWARDS_NEW = '/loyalty/newrewards',
  LOYALTY_SETUP = '/loyalty/setup',
  MARKETING = '/marketing',
  MARKETING_CALENDAR = '/marketing/calendar',
  MARKETING_EVENTS = '/marketing/events',
  MARKETING_EVENTS_NEW = '/marketing/createevents',
  MARKETING_EVENTS_EDIT = '/marketing/editevents/',
  MARKETING_NEWS = '/marketing/news',
  MARKETING_NEWS_NEW = '/marketing/createnews',
  MARKETING_NEWS_EDIT = '/marketing/editnews/',
  MARKETING_BLOG_LIST = '/marketing/blogs',
  MARKETING_BLOG_EDIT = '/marketing/blog/edit/',
  MARKETING_BLOG_NEW = '/marketing/blog/new',
  OMNICHANNEL_PUSH = '/omnichannel/push-notifications',
  OMNICHANNEL_PUSH_NEW = '/omnichannel/newpush-notifications',
  OMNICHANNEL_PUSH_EDIT = '/omnichannel/push-notifications/',
  MARKETING_SALE = '/marketing/sale',
  MARKETING_SALE_EDIT = '/marketing/editsale/',
  MARKETING_SALE_NEW = '/marketing/createsale',
  MARKETING_SCENARIOS = '/marketing/scenarios',
  MARKETING_SCENARIOS_NEW = '/marketing/newscenarios',
  MARKETING_MOBILE_APP_SETTINGS = '/marketing/mobile-app-settings',
  MARKETING_STORIES = '/marketing/stories',
  MARKETING_STORIES_NEW = '/marketing/stories/new',
  MARKETING_STORIES_EDIT = '/marketing/stories/',
  SURVEY = '/marketing/survey',
  SURVEY_NEW = '/marketing/survey/new',
  SURVEY_EDIT = '/marketing/survey/edit/',
  SURVEY_RESPONSES = '/marketing/survey/responses/',
  PEOPLE = '/people',
  OMNICHANNEL_DASHBOARD = '/omnichannel/dashboard',
  OMNICHANNEL_CUSTOMER = '/omnichannel/customer/',
  OMNICHANNEL_CUSTOMERS = '/omnichannel/customers',
  OMNICHANNEL_SEGMENTATION = '/omnichannel/segmentation',
  OMNICHANNEL_AUDIENCES = '/omnichannel/audiences',
  OMNICHANNEL_NEW_AUDIENCE = '/omnichannel/newaudience',
  OMNICHANNEL_AUDIENCE_EDIT = '/omnichannel/editaudience/',
  OMNICHANNEL_IN_APP_CAMPAIGN = '/omnichannel/in-app',
  OMNICHANNEL_IN_APP_CAMPAIGN_NEW = '/omnichannel/in-app/new',
  OMNICHANNEL_IN_APP_CAMPAIGN_EDIT = '/omnichannel/in-app/',
  OMNICHANNEL_SMS_CAMPAIGN = '/omnichannel/sms',
  OMNICHANNEL_SMS_CAMPAIGN_NEW = '/omnichannel/sms/new',
  OMNICHANNEL_SMS_CAMPAIGN_EDIT = '/omnichannel/sms/',
  OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_LIST = '/omnichannel/digital-signage/campaigns',
  OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_EDIT = '/omnichannel/digital-signage/campaigns/',
  OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_NEW = '/omnichannel/digital-signage/campaigns/new',
  OMNICHANNEL_EMAIL = '/omnichannel/email/newsletter',
  OMNICHANNEL_NEWSLETTER_LIST = '/omnichannel/email/newsletter',
  OMNICHANNEL_NEWSLETTER_NEW = '/omnichannel/email/newsletter/new',
  OMNICHANNEL_NEWSLETTER_EDIT = '/omnichannel/email/newsletter/edit/',
  OMNICHANNEL_TEMPLATE = '/omnichannel/email/template',
  OMNICHANNEL_TEMPLATE_NEW = '/omnichannel/email/template/new',
  OMNICHANNEL_TEMPLATE_EDIT = '/omnichannel/email/template/edit/',
  OMNICHANNEL_AUTOMATIONS = '/omnichannel/automations',
  OMNICHANNEL_AUTOMATIONS_NEW = '/omnichannel/automations/new',
  OMNICHANNEL_AUTOMATIONS_EDIT = '/omnichannel/automations/edit/',
  OMNICHANNEL_TRACKING_LINKS = '/omnichannel/tracking/links',
  OMNICHANNEL_TRACKING_LINKS_EDIT = '/omnichannel/tracking/edit',
  OMNICHANNEL_TRACKING_LINKS_NEW = '/omnichannel/tracking/new',
  OMNICHANNEL_NEWSLETTER_SUBSCRIBERS = '/omnichannel/newsletter-settings/subscribers',
  REPORTS_DASHBOARD = '/reports/dashboard',
  REPORTS_HISTORICAL = '/reports/historical-reports',
  REPORT_HISTORICAL = '/reports/historical-report/',
  SETTINGS_GENERAL = '/settings/general',
  SETTINGS_TEAM = '/settings/team',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_GALLERIES = '/settings/galleries',
  SETTINGS_GALLERIES_NEW = '/settings/galleries/new',
  SETTINGS_GALLERIES_EDIT = '/settings/galleries/edit',
  SETTINGS_FAQ = '/settings/faq',
  SETTINGS_EXPORT = '/settings/export/',
  SETTINGS_WIFI = '/settings/wifi',
  SETTINGS_EMAILS = '/settings/emails',
  SETTINGS_DIGITAL_SIGNAGE_DEVICE_NEW = '/settings/digital-signage/device/new',
  SETTINGS_DIGITAL_SIGNAGE_DEVICE_EDIT = '/settings/digital-signage/device/',
  SETTINGS_DIGITAL_SIGNAGE_DEVICE_LIST = '/settings/digital-signage/devices',
  SETTINGS_DIGITAL_SIGNAGE_LOCATION_NEW = '/settings/digital-signage/location/new',
  SETTINGS_DIGITAL_SIGNAGE_LOCATION_EDIT = '/settings/digital-signage/location/',
  SETTINGS_DIGITAL_SIGNAGE_LOCATION_LIST = '/settings/digital-signage/location',
  TERMS_AND_CONDITIONS = '/settings/terms-and-conditions',
  DOCUMENTATION = '/settings/documentation',
  COOKIES = '/settings/cookies',
  PRIVACY_POLICY = '/settings/privacy-policy',
  ADMIN_USER_NEW = '/admin/newuser',
  ADMIN_USER = '/admin/user',
  COMMUNICATION_CHAT = '/communication/chat',
  COMMUNICATION_SUPPORT = '/communication/support/questions',
  WEB_CUSTOMIZATION = '/settings/web/customization',
  MOBILE_CUSTOMIZATION = '/settings/mobile/customization',
  KIOSK_CUSTOMIZATION = '/settings/kiosk/customization',
  ALL_CUSTOMIZATION = '/settings/all/customization',
  FILES_LIST = '/settings/files',
  LOGS = '/settings/logs'
}
