import { useAuthStore } from '@/store/auth'
import { SubcategoryType, SelectedTenantType, SelectedLangItemType, RestApiResponseItem, ImageInEntity } from '@/imports'
import { isBefore, isAfter, subWeeks, startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear } from 'date-fns'
import { storeToRefs } from 'pinia'

// TRANSLATIONS //////////////////////////////////////////////////

export function translate(translations: Array<Object>, key: string) {
  const { settingsApi } = storeToRefs(useAuthStore())
  const availableLanguages = settingsApi.value?.availableLanguages
  const lang = availableLanguages?.find(lang => lang.order === 1)?.locale || ''

  let translation: any = translations.find((item: any) => {
    return item[key] && item.locale === lang
  })

  if (!translation) {
    translation = translations.find((item: any) => !!item[key]) // Default value as first element for given key
  }

  translation = translation ? translation[key] : ''
  return translation
}

export function getTranslationsForInput(translations: Array<Object>, key: string) {
  if (!translations) return {}

  return translations.reduce((acc: any, item: any) => {
    acc[item.locale] = item[key]
    return acc
  }, {})
}

export function checkDefauletLang(obj: any, lang: string) {
  if (lang in obj && obj[lang] !== '') {
    return true
  } else {
    return false
  }
}

// VALIDATION ERRORS //////////////////////////////////////////////

export function parseErrorsToString(errors: any) {
  let result = ''

  errors.forEach((item: any) => (result += '<div><small>' + item + '</small></div>'))

  return result
}

// ARRAY HELPERS //////////////////////////////////////////////////

// Array recursive find by id
export function recursiveFindById(data: Array<Object>, id: number, childrenKey: string = 'subcategory') {
  let result = null

  data.some(function iter(item: any) {
    if (item.id === id) {
      result = item
      return true
    }
    return Array.isArray(item[childrenKey]) && item[childrenKey].some(iter)
  })
  return result
}

// Tree structure array to flat array for selects
export function treeForSelect(
  data: Array<Object> | null,
  titleKey: string = 'title',
  childrenKey: string = 'subcategory',
  levelPrefix: string = '&nbsp;&nbsp;&nbsp;&nbsp;'
) {
  const result: any[] = []

  if (!data) return result

  function recursion(data: Array<Object>, level = 0) {
    data.forEach((item: any) => {
      item.prefixTitle = levelPrefix.repeat(level) + item[titleKey]
      result.push(item)
      if (item[childrenKey] && item[childrenKey].length) {
        recursion(item[childrenKey], ++level)
      }

      level = level > 0 ? --level : 0 // Min value is 0
    })
  }

  recursion(data)

  return result
}

export function transformTitle(array: any) {
  return array.reduce((acc: any, item: any) => {
    acc[item.locale] = item.title
    return acc
  }, {})
}

export function transformDesc(array: any) {
  return array.reduce((acc: any, item: any) => {
    acc[item.locale] = item.description
    return acc
  }, {})
}

export function isBase64(str: string) {
  const base64Regex = /data:image\//
  return base64Regex.test(str) ? false : true
}

export function filterEmptyFields(data: any) {
  const filteredData = {}
  for (const key in data) {
    if (Array.isArray(data[key])) {
      // Check if array is not empty
      if (data[key].length !== 0) {
        filteredData[key] = data[key]
      }
    } else {
      // Check if other data types are not null, not empty strings and not zero
      if (data[key]) {
        filteredData[key] = data[key]
      }
    }
  }
  return filteredData
}

/**
 * Get source image
 *
 * @param data
 * @param additionalImageFormatterQueries Optional query string to append to the image url to format the image. Example: "w=100&h=100&fit=crop".
 *  All options can be found here https://glide.thephpleague.com/1.0/api/quick-reference/
 */
export function getSrcImage(data: string, additionalImageFormatterQueries?: string) {
  const base_url = import.meta.env.VITE_BASE_URL
  let filteredData = base_url + '/img/' + data

  // if additionalImageFormatterQueries is not empty, append it to the image url
  if (additionalImageFormatterQueries) {
    data += '?' + additionalImageFormatterQueries
    filteredData += '?' + additionalImageFormatterQueries
  }

  if (data.includes('http')) {
    return data
  } else {
    return filteredData
  }
}

export function getIdsCategories(data: SubcategoryType[]) {
  const temp: number[] = []
  if (data.length) {
    data.forEach((el: any) => {
      temp.push(el.id)
    })
  }
  return temp
}

export function convertToObjectWithLanguages(array: any) {
  const resultObject = {}

  for (const key in array) {
    if (array.hasOwn(key)) {
      const locale = key.split(':')[1]
      resultObject[locale] = array[key]
    }
  }

  return resultObject
}

export function phoneNumberFormatting(item: any) {
  let temp = []
  if (item && item.length) {
    temp.push(item.replace(/(\d{3})/g, '$1 ').trim())
  } else {
    temp = []
  }
  return temp
}

// Check validation dates

export const disabledStartDateFunction = (finishDate: string) => {
  return (dateToCheck: Date) => {
    if (finishDate) {
      const finishDateObj = new Date(finishDate)
      finishDateObj.setDate(finishDateObj.getDate() - 1)
      return isBefore(dateToCheck, finishDateObj)
    }
    return false
  }
}

export const disabledFinishDateFunction = (startDate: string) => {
  return (dateToCheck: Date) => {
    if (startDate) {
      return isAfter(dateToCheck, new Date(startDate))
    }
    return false
  }
}

// extract Ids Selected Tenant
export function extractIdsSelectedTenant(array: SelectedTenantType[]) {
  if (array !== null && Object.keys(array).length) {
    return array.map((item: SelectedTenantType) => item.value)
  } else {
    return []
  }
}

export function extractIds(array: SelectedTenantType[]) {
  if (array !== null && Object.keys(array).length) {
    return array.map((item: SelectedTenantType) => item.id)
  } else {
    return []
  }
}
// Any
export function extractOnlyId(array: any[]) {
  if (array !== null && Object.keys(array).length) {
    return array.map((item: any) => item.id)
  } else {
    return []
  }
}

export function extractOnlyIdAsValue(array: any[]) {
  if (array !== null && Object.keys(array).length) {
    return array.map((item: any) => item.value)
  } else {
    return []
  }
}

// extract Locale Selected Lang
export function extractLocaleSelectedLang(array: SelectedLangItemType[]) {
  if (Object.keys(array).length) {
    return array.map((item: SelectedLangItemType) => item.locale)
  } else {
    return []
  }
}

export function setTitle(item: any) {
  const temp = {}
  if (Object.keys(item).length) {
    for (const lang in item) {
      temp[`title:${lang}`] = item[lang]
    }
  }
  return temp
}

export function setDesc(item: any) {
  const temp = {}
  if (Object.keys(item).length) {
    for (const lang in item) {
      temp[`description:${lang}`] = item[lang]
    }
  }
  return temp
}

// Getting Names Notifications
export function getNamesNotifications(arr: any) {
  return arr.map(function (item: any) {
    return item.name
  })
}

// Checking local storage
export function checkingViewMode() {
  const temp = localStorage.getItem('viewMode')
  if (temp) {
    return temp
  } else {
    return 'cards'
  }
}

export function setViewMode(name: string) {
  localStorage.setItem('viewMode', name)
}

// "Chybová Hláška #5" -> chybova_hlaska_5
export const i18Errors = (v: string): string => {
  const rv = v.replace(/\s+/g, '_').toLowerCase()
  return rv.replace(/[^a-zA-Z0-9_]/g, '')
}

// Set filter
export function setFilterSetup(filter: object) {
  localStorage.setItem('pagefilter', JSON.stringify(filter))
}

// TODO: Peter -> fix this
interface PresetRange {
  label: string
  range: Date[]
}

export const presetRanges: PresetRange[] = [
  { label: 'today', range: [new Date()] },
  {
    label: 'last_week',
    range: [subWeeks(new Date(), 1), new Date()]
  },
  {
    label: 'last_14_days',
    range: [subWeeks(new Date(), 2), new Date()]
  },
  { label: 'this_month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
  {
    label: 'last_month',
    range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
  },
  {
    label: 'last_3_months',
    range: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(subMonths(new Date(), 1))]
  },
  { label: 'this_year', range: [startOfYear(new Date()), endOfYear(new Date())] },
  {
    label: 'last_12_months',
    range: [startOfMonth(subMonths(new Date(), 12)), endOfMonth(new Date())]
  }
]

/**
 * Get translated error message from RestApiResponseItem
 *
 * @param restApiResponse
 * @param translationKeyPrefix Prefix that will be used to prepend the translation key, like 'news_view.errors', etc.
 * @return error message or null
 */
export function getTranslatableErrorMessageFromRestApiResponse(
  restApiResponse: RestApiResponseItem,
  translationKeyPrefix: string | null = null
): string | null {
  if (restApiResponse.status === 'error') {
    // make sure to transform dashes (-) to underscores (_)
    return restApiResponse.first_translatable_error_key
      ? (translationKeyPrefix ? translationKeyPrefix + '.' : '') + restApiResponse.first_translatable_error_key.replaceAll('-', '_')
      : restApiResponse.message
  }

  return null
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function aspectRatioString(width: number, height: number): string {
  function gcd(a: number, b: number): number {
    if (b === 0) {
      return a
    }
    return gcd(b, a % b)
  }

  const divisor = gcd(Number(width), Number(height))
  const aspectWidth = Number(width) / divisor
  const aspectHeight = Number(height) / divisor

  return `${aspectWidth}/${aspectHeight}`
}

export function isImage(extension: string): boolean {
  return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension)
}

export function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

export function formatVideoLengthVerbose(seconds: number): string {
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes} minute${minutes !== 1 ? 's' : ''}${remainingSeconds > 0 ? ` ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : ''}`
  } else {
    const hours = Math.floor(seconds / 3600)
    const remainingMinutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${hours} hour${hours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}` : ''}${remainingSeconds > 0 ? ` ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : ''}`
  }
}

export function getExtensionFromFileName(filename: string): string {
  if (typeof filename !== 'string') {
    throw new Error('Filename must be a string')
  }

  const extension = filename.split('.').pop() || ''
  return extension === filename ? '' : extension
}

/**
 * Get the image URL from the images array by aspect ratio
 * ! expand ImageInEntity interface if new entity is added
 *
 * @param imagesArray The images array you get from the backend call
 * @param aspectRatio Like '16/9' or '1/1'
 * @param additionalImageFormatterQueries Additional queries for the image formatter like 'w=1000&h=1000&fit=crop&fm=pjpg'.
 * @param fallback If true, any image will be returned if the aspect ratio is not found.
 */
export function getImageSrcByAspectRatio(
  imagesArray: ImageInEntity[],
  aspectRatio: string,
  additionalImageFormatterQueries?: string,
  fallback: boolean = false
): string | undefined {
  // if array is empty, return null
  if (!imagesArray.length) return undefined

  // find the image array entry that has aspect_ratio we seek
  let image = imagesArray.find((value: ImageInEntity) => value.aspect_ratio === aspectRatio)

  if (!image || !image.image) {
    if (fallback === true) {
      // if not found, return the first image in the array
      image = imagesArray[0]
    } else {
      // if not found, return undefined
      return undefined
    }
  }

  let finalUrl = image.image
  if (additionalImageFormatterQueries) {
    finalUrl += '?' + additionalImageFormatterQueries
  }

  // return the image url and append additional image formatter queries if any
  return finalUrl
}

/**
 * Normalize used for search. When searching "civava" then "ČivAva" can be also found
 * - Normalizes accented characters using Unicode Normalization Form D (NFD).
 * - Removes diacritics (accents) from the string.
 * - Removes punctuation and special characters except for %, @, _, ., and -
 * - Converts the string to lowercase.
 *
 * @param str - The string to be normalized.
 * @returns The normalized string.
 */
export function normalizeString(str: string): string {
  if (str === null || str === undefined) return ''
  return str
    .normalize('NFD') // Normalize accented characters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics (accents)
    .replace(/[^\w\s%@_.-]/g, '') // Remove special characters except some mentioned in the description
    .toLowerCase()
}

/**
 * Highlights searched string
 *
 * @param data - The string in which to search for matches.
 * @param search - The string to search for within the data.
 * @returns html string with highlighted matches
 *
 * @example
 * String in which to search: "Pes Čivava je malý pes"
 * Searched: "civava", Highlighted: "<span class="highlighted" style="background: yellow;">Čivava</span>"
 */
export function highlightMatch(data: string, search: string): string {
  if (!search) return data

  const normalizedData = normalizeString(data)
  const normalizedSearch = normalizeString(search)

  // End execution if the normalized data or search string is empty. Can cause out of memory
  if (normalizedData === '') return ''
  if (normalizedSearch === '') return ''

  // Create a regular expression with the normalized search string, case-insensitive
  const regex = new RegExp(`(${normalizedSearch})`, 'ig')

  // Collect matches with indices in the original string
  const matches = []
  let match
  let startIndex = 0

  // Use a loop to find all matches with their positions in the original data
  while ((match = regex.exec(normalizedData)) !== null) {
    const originalMatchStart = normalizedData.indexOf(match[0], startIndex)
    const originalMatchEnd = originalMatchStart + match[0].length
    matches.push({
      start: originalMatchStart,
      end: originalMatchEnd
    })
    startIndex = originalMatchEnd
  }

  // Highlight the matches in the original data
  let highlightedData = ''
  let currentIndex = 0

  for (const { start, end } of matches) {
    highlightedData += data.slice(currentIndex, start)
    highlightedData += `<span class="highlighted" style="background: yellow;">${data.slice(start, end)}</span>`
    currentIndex = end
  }

  // Append any remaining part of the original data
  highlightedData += data.slice(currentIndex)

  return highlightedData
}

export function highlightDeletedRow(id: number): void {
  document.querySelectorAll('.row-id-' + id).forEach(item => {
    const element = item as HTMLElement
    element.classList.add('highlight-deleted-item')
  })
}

export function checkformatTime(timeFormat: string) {
  let temp = ''
  if (timeFormat.includes('XM')) {
    temp = timeFormat.toLowerCase().replace('xm', 'A')
  } else {
    temp = timeFormat.replace(':MM', ':mm').replace('SS', 'ss').replace('XM', '')
  }
  return temp
}
