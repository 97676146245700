// Utilities
import { defineStore } from 'pinia'
export interface appStoreTypes {
  navWidth: number
  tenantId: string | null
  loading: boolean
  infoGlobalId: number
  snackbar: {
    show: boolean
    content: {
      type: 'success' | 'error' | 'warning' | 'regular' | null
      title: null | string
      text: null | string
    }
  }
  dateFormat: string
  timeFormat: string
  alerts:
    | null
    | {
        type: 'success' | 'error' | 'warning' | 'regular'
        title: string
        active: boolean
        text: null | string
      }[]
}

export const useAppStore = defineStore('app', {
  state: (): appStoreTypes => {
    return {
      tenantId: null,
      navWidth: 280,
      loading: false,
      infoGlobalId: 0,
      snackbar: {
        show: false,
        content: {
          type: null,
          title: null,
          text: null
        }
      },
      dateFormat: 'DD.MM.YYYY',
      timeFormat: 'HH:mm',
      alerts: null
    }
  },
  getters: {
    getTenantId(state: appStoreTypes): string {
      if (state.tenantId) {
        return state.tenantId
      } else {
        return window.location.host.split('.')[0] + '.website.mysimplaq.com'
      }
    }
  },
  actions: {
    toggleLoading(value: boolean) {
      this.loading = value
    },
    setInfoGlobalId(value: number) {
      this.infoGlobalId = value
    },
    setSnackbar(
      show: boolean,
      content: {
        type: 'success' | 'error' | 'warning' | 'regular' | null
        title: null | string
        text: null | string
      }
    ) {
      if (show) {
        this.snackbar.show = true
        this.snackbar.content = content
      }
    },
    setAlert(
      alert: {
        type: 'success' | 'error' | 'warning' | 'regular'
        title: string
        active: boolean
        text: null | string
      },
      timeout: number = 3000
    ) {
      if (this.alerts === null) {
        this.alerts = []
      }
      this.alerts.push(alert)
      setTimeout(() => {
        this.removeAlert(alert)
      }, timeout)
    },
    removeAlert(alert: { type: 'success' | 'error' | 'warning' | 'regular'; title: string; active: boolean; text: null | string }) {
      if (this.alerts === null) {
        this.alerts = []
      }
      const index = this.alerts.indexOf(alert)
      if (index !== -1) {
        this.alerts.splice(index, 1)
      }
    },
    setTenantId(id: string) {
      this.tenantId = id
      localStorage.setItem('tenant_id', id)
    }
  }
})
