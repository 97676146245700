import { defineStore } from 'pinia'
import type { ThreadOverview, ThreadMessage } from '@/types/chat/Chat.types'
import type { Customer } from '@/types/pages/customers/Customer.types'
import { RestApi } from '@/imports'
export interface ChatStoreTypes {
  threads: ThreadOverview[]
  threadMessages: ThreadMessage[]
  selectedThread: ThreadOverview | null
  channels: any[]
  activeCustomer: Customer | null
  editedMessageId: number | null
  editedMessageContent: string | null
  unreadCount: number
  isUnreadCountLoading: boolean
}

export const useChatStore = defineStore('chatStore', {
  state: (): ChatStoreTypes => ({
    threads: [],
    threadMessages: [],
    selectedThread: null,
    channels: [],
    activeCustomer: null,
    editedMessageId: null,
    editedMessageContent: null,
    unreadCount: 0,
    isUnreadCountLoading: true
  }),
  getters: {
    formattedUnreadCount: state => (state.unreadCount > 100 ? '99+' : state.unreadCount)
  },
  actions: {
    async getUnreadCount() {
      try {
        this.isUnreadCountLoading = true
        const unreadCountResponse = await RestApi.getTotalUnreadCount()
        if (unreadCountResponse.data.status === 'success') {
          this.unreadCount = unreadCountResponse.data.data.unread_messages
        }
      } finally {
        this.isUnreadCountLoading = false
      }
    }
  }
})
