import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
export interface authStoreTypes {
  authToken: null | string
  authData: {
    access_token: string
    permissions: any
    role: {
      id: number
      name: string
      role: string
    }
  } | null // TODO: add better typing here
  authStatus: any
  errorAuthData: {
    email: string
    password: string
  }
  formData: {
    email: string
    password: string
  }
  permissions: {
    menu: {
      default: {
        login: boolean
      }
      reports: {
        _group: boolean
        dashboard: boolean
        reports: boolean
      }
      lease: {
        _group: boolean
        tenants: boolean
        organizations: boolean
      }
      marketing: {
        _group: boolean
        calendar: boolean
        push_notifications: boolean
        news: boolean
        events: boolean
        sale: boolean
        scenarios: boolean
      }
      loyalty: {
        _group: boolean
        setup: boolean
        rewards: boolean
        receipts: boolean
        templates: boolean
        coupons: boolean
      }
      people: {
        _group: boolean
        customers: boolean
      }
      assets: {
        _group: boolean
        floorplan: boolean
      }
      settings: {
        _group: boolean
        my_profile: boolean
        general: boolean
        terms: boolean
        faq: boolean
      }
      superadmin: {
        _group: boolean
        users: boolean
      }
    }
  }
  user: User
  userGroup: number
  userGroupGuest: number
  settingsApi: SettingsType | null
}

export interface SettingsType {
  receiptsLabels: Array<{
    id: number
    name: string
    fill: string
    stroke: string
  }>
  availableLanguages: Array<{
    id: number
    locale: string
    language: string
    order: number
    deleted_at?: string | null
  }>
  availableСountries: Array<{
    id: number
    code_full: string
    code_number: string
    code_short: string
    country: string
  }>
  eventsAudience: {
    [key: string]: string
  }
  eventsStatus: {
    [key: string]: number
  }
  newsAudience: {
    [key: string]: string
  }
  newsStatus: {
    [key: string]: string | number
  }
  defaultLanguage: {
    id: number
    locale: string
    language: string
  }
  rewardsAudience: {
    [key: string]: string
  }
  rewardsActivationTypes: {
    [key: string]: string
  }
  rewardsTiers: Array<{
    id: number
    purchases: number
    audience: null
    disabled: null
    created_at: null
    updated_at: null
    title: string
    translations: Array<{
      id: number
      tier_id: number
      locale: string
      title: string
    }>
  }>
  rewardRedeems: any[]
  rewardsPersonalLimits: any[]
  notificationsTypes: Array<{
    id: number
    name: string
  }>
  loyalty_program: {
    id: number
    mall_id: number
    title: string
    description: null | string
    created_at: string
    updated_at: string
  }
  receiptTemplateLabels: Array<{
    key: string
    title: string
    fill: string
    stroke: string
    desc: string
  }>
  dateFormats: []
  timeFormats: string[]
  timeZones: string[]
  audiences: any[]
  floors: Array<{
    level: number
  }>
  companyCategories: Array<{
    id: number
    parent_id: null | number
    sort: null | number
    company_category: string
    description: null | string
    translations: Array<{
      id: number
      company_category_id: number
      locale: string
      company_category: string
      description: string
    }>
  }>
  companyTypes: Array<Object>
  organizations: Array<{
    id: number
    name: string
  }>
  postCategoriesVisibility: Array<Object>
  currencies: Array<CurrencyType>
  decimalsSeparators: ['comma', 'dot', 'none']
  amenities_allowed_price_times: any[]
  amenities_allowed_reservation_times: any[]
  receipts_status_codes: any[]
  onboardingFamilyMembers: any[]
  ageGroups: any[]
}

export type CurrencyType = {
  code: string
  default: boolean | null
  id: number
  number: string
  title: string
}

export interface User {
  created_at: string | null
  email: string
  first_name: null | string
  id: number | null
  lang: { id: number; locale: string; language: string; order: number; title?: string; value?: string }
  last_name: null | string
  picture: null | string
  phone: null | string
  role_id: number | null
  tenant_id: number | null
  updated_at: string | number
}
export interface UserRole {
  active: boolean
  default: boolean
  description: null | string
  id: number
  name: string
  role: string
}
export interface UserProfileAndPermissions {
  access_token: string
  expires_in: number
  permissions: { [key: string]: { [key: string]: { [key: string]: boolean } } }
  role: UserRole
  roles: Array<UserRole>
  token_type: string
  user: User
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): authStoreTypes => ({
    authToken: null,
    authData: null,
    errorAuthData: {
      email: '',
      password: ''
    },
    formData: {
      email: '',
      password: ''
    },
    permissions: {
      menu: {
        default: {
          login: true
        },
        reports: {
          _group: false,
          dashboard: false,
          reports: false
        },
        lease: {
          _group: false,
          tenants: true,
          organizations: true
        },
        marketing: {
          _group: false,
          calendar: false,
          push_notifications: false,
          news: false,
          events: false,
          sale: false,
          scenarios: false
        },
        loyalty: {
          _group: false,
          setup: false,
          rewards: false,
          receipts: false,
          templates: false,
          coupons: false
        },
        people: {
          _group: false,
          customers: false
        },
        assets: {
          _group: false,
          floorplan: false
        },
        settings: {
          _group: false,
          my_profile: false,
          general: false,
          terms: false,
          faq: true
        },
        superadmin: {
          _group: false,
          users: false
        }
      }
    },
    user: {
      id: null,
      email: '',
      created_at: '',
      updated_at: '',
      first_name: null,
      last_name: null,
      picture: null,
      phone: null,
      role_id: null,
      tenant_id: null,
      lang: {
        id: 0,
        locale: '',
        language: '',
        order: 1
      }
    },
    userGroup: 5,
    authStatus: null,
    settingsApi: null,
    /*
  User group
  1 Portal administrator
  2 Manager
  3 Service account
  4 Content administrator
  5 Guest
  7 Receipt manager
   */
    userGroupGuest: 5
  }),
  getters: {
    isAdminOrManagerOrService(): boolean {
      return this.getUserGroup === 1 || this.getUserGroup === 2 || this.getUserGroup === 3
    },
    getUserGroup(state: authStoreTypes): number {
      const storageToken = localStorage.getItem('user_group')
      if (state.userGroup) {
        return state.userGroup
      } else {
        return parseInt(storageToken!)
      }
    }
  },
  actions: {
    login(data: any) {
      this.clearErrorAuthData()
      if (data.status === 'success') {
        const user = data.data.user as User
        user.lang = { locale: data.data.user.lang.value, language: data.data.user.lang.title, id: 0, order: 0 }
        this.saveAuthData(data.data)
        this.clearFormData()

        Sentry.setUser({
          id: user.id === null ? 0 : user.id,
          email: user.email,
          username: user.last_name !== null ? `${user.first_name} ${user.last_name}` : user.first_name ?? ''
        })

        Sentry.setTag('locale', data.data.user.lang.value)
        Sentry.setTag('language', data.data.user.lang.title)
      } else {
        Sentry.setUser(null)

        this.saveErrorAuthData(data.data)
      }
    },
    async logout() {
      this.clearAuthToken()

      localStorage.clear()
      Sentry.setUser(null)
    },
    changeLanguage(value: { id: number; locale: string; language: string; order: number }) {
      this.user.lang = value
      Sentry.setTag('locale', value.locale)
      Sentry.setTag('language', value.language)
    },
    clearErrorAuthData() {
      this.errorAuthData = {
        email: '',
        password: ''
      }
    },
    saveAuthData(authData: { access_token: string; permissions: any; role: { id: number; name: string; role: string }; user: User }) {
      localStorage.removeItem('access_token')
      localStorage.setItem('access_token', authData.access_token)
      localStorage.setItem('permissions', JSON.stringify(authData.permissions))
      const userGroupLocal = authData.role && authData.role.id ? authData.role.id : this.userGroupGuest
      localStorage.setItem('user_group', userGroupLocal.toString())
      this.user = authData.user
      this.authData = authData
      this.authToken = authData.access_token
      this.permissions = authData.permissions
      this.userGroup = userGroupLocal
    },
    clearFormData() {
      this.formData = {
        email: '',
        password: ''
      }
    },
    saveErrorAuthData(errorAuthData: any) {
      if (errorAuthData) {
        if (errorAuthData.email && errorAuthData.email.length) {
          this.errorAuthData.email = errorAuthData.email[0]
        } else {
          this.errorAuthData.email = errorAuthData.email
        }
        if (errorAuthData.password && errorAuthData.password.length) {
          this.errorAuthData.password = errorAuthData.password[0]
        } else {
          this.errorAuthData.password = errorAuthData.password
        }
      }
    },
    setUserData(firstName: string, lastName: string | null, email: string, phone: string | null, picture: string | null) {
      this.user.first_name = firstName
      this.user.last_name = lastName
      this.user.picture = picture
      this.user.email = email
      this.user.phone = phone

      Sentry.setUser({
        email: email,
        username: lastName !== null ? `${firstName} ${lastName}` : firstName ?? ''
      })
    },
    clearAuthToken() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('permissions')
      localStorage.removeItem('user_group')
      localStorage.removeItem('user')
      this.authToken = null
      this.authData = null
      this.userGroup = 5

      Sentry.setUser(null)
    },
    saveStatus(status: any) {
      this.authStatus = status
    },
    setSettingsApi(data: any) {
      this.settingsApi = data
    }
  }
})
