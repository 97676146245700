<template>
  <div class="global-info-wrap">
    <div class="global-info-block">
      <div class="global-info-header">
        <div class="global-info-header-ttile">
          <IconBase name="Scenarios" width="22" height="22" />
          <h4>{{ $t('rewards') }}</h4>
        </div>
        <div class="btn-group">
          <IconBase name="Arrowupbig" width="12" height="12" />
          <IconBase name="Penciledit" width="17" height="16" />
          <IconBase name="Delete" color="#000000" width="17" height="19" />
          <IconBase @click="closeInfo()" name="Cross" width="12" height="12" />
        </div>
      </div>
      <div class="global-info-body">
        <div class="row">
          <div class="col-name wide">{{ $t('status') }}</div>
          <div class="col-value">
            <Statusbutton :id="1" :status="1" :options="typeStatus" @editstatus="oneEditStatusEvent" />
          </div>
        </div>
        <div class="row">
          <div class="col-name wide">Created Date</div>
          <div class="col-value">January 06, 2023 2:43 AM</div>
        </div>
        <div class="row">
          <div class="col-name wide">From</div>
          <div class="col-value">January 06, 2023 2:43 AM</div>
        </div>
        <div class="row">
          <div class="col-name wide">To</div>
          <div class="col-value">January 06, 2023 2:43 AM</div>
        </div>
        <div class="row">
          <div class="col-name wide">Frequency</div>
          <div class="col-value">Every time</div>
        </div>

        <div class="global-info-tab">
          <VTabs v-model="tab">
            <VTab value="information">{{ $t('information') }}</VTab>
            <VTab value="discussions">{{ $t('discussions') }}</VTab>
          </VTabs>
          <VWindow v-model="tab">
            <VWindowItem value="information">
              <div class="global-info-tab-item"></div>
            </VWindowItem>

            <VWindowItem value="discussions">
              <div class="global-info-tab-item"></div>
            </VWindowItem>
          </VWindow>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import IconBase from '@/components/icons/IconBase.vue'
import Statusbutton from '@/components/ui/button/Statusbutton.vue'
import { ref, onMounted } from 'vue'
import { useAppStore } from '@/store/app'
import { typeStatus } from '@/controllers/Statuses'
const appStore = useAppStore()

const props = defineProps({
  id: {
    type: Number
  }
})

// Default value for tabs
const tab = ref('information')

function oneEditStatusEvent() {
  // TODO
}
function closeInfo() {
  appStore.setInfoGlobalId(0)
}

function fetchData() {
  // TODO
}

onMounted(() => {
  fetchData()
})
</script>
