import { reactive } from 'vue'
import { useAuthStore } from '@/store/auth'
import { Routes } from '@/imports'

type MenuItem = {
  type: string
  to: string
  name: string
  ico: any
  permissions: boolean
}
type Menu = {
  [category: string]: MenuItem[]
}

// export const primaryMenu = [
//   { to: Routes.REPORTS_DASHBOARD, ico: 'ReportsIcon', name: 'reports', permissions: false },
//   { to: Routes.MARKETING_CALENDAR, ico: 'MarketingIcon', name: 'marketing', permissions: false },
//   { to: Routes.LEASE_TENANTS, ico: 'LeaseIcon', name: 'lease', permissions: false },
//   { to: Routes.LOYALTY_RECEIPTS, ico: 'LoyaltyIcon', name: 'loyalty', permissions: false },
//   { to: Routes.SALE_AMENITIES, ico: 'MarketIcon', name: 'sale', permissions: true },
//   { to: Routes.OMNICHANNEL_CUSTOMERS, ico: 'PeopleIcon', name: 'people', permissions: false },
//   { to: Routes.ASSETS_FLORPLAN_FLOORPLANS, ico: 'AssetsIcon', name: 'assets', permissions: false },
//   { to: Routes.SETTINGS_PROFILE, ico: 'SettingsIcon', name: 'settings', permissions: false },
//   { to: Routes.ADMIN_USERS, ico: 'AdminIcon', name: 'superadmin', permissions: false }
// ]

export const primaryMenu = [
  { to: '/', ico: 'DashboardIcon', name: 'reports', permissions: false },
  { to: Routes.MARKETING_CALENDAR, ico: 'MarketingIcon', name: 'marketing', permissions: false },
  { to: Routes.LEASE_TENANTS, ico: 'LeaseIcon', name: 'lease', permissions: false },
  { to: Routes.LOYALTY_DASHBOARD, ico: 'LoyaltyIcon', name: 'loyalty', permissions: true },
  { to: Routes.OMNICHANNEL_DASHBOARD, ico: 'PeopleIcon', name: 'omnichannel', permissions: true },
  { to: Routes.COMMUNICATION_CHAT, ico: 'CommunicationIcon', name: 'communication', permissions: true },
  { to: Routes.ASSETS_FLORPLAN_FLOORPLANS, ico: 'AssetsIcon', name: 'assets', permissions: false },
  { to: Routes.SETTINGS_PROFILE, ico: 'SettingsIcon', name: 'settings', permissions: false }
]

export const secondaryMenu: Menu = reactive({
  reports: [
    {
      type: 'link',
      to: Routes.REPORTS_DASHBOARD,
      ico: 'DashboardIcon',
      name: 'dashboard',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.REPORTS_HISTORICAL,
      ico: 'ReportsIcon2',
      name: 'historical_reports',
      permissions: false
    }
  ],
  loyalty: [
    {
      type: 'link',
      to: Routes.LOYALTY_DASHBOARD,
      ico: 'DashboardIcon',
      name: 'dashboard',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.LOYALTY_RECEIPTS,
      ico: 'ReceiptIcon',
      name: 'receipts',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.LOYALTY_REWARDS,
      ico: 'RewardsIcon',
      name: 'rewards',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.LOYALTY_COUPONS,
      ico: 'CouponsIcon',
      name: 'coupons',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.LOYALTY_SETUP,
      ico: 'SetupIcon',
      name: 'loyalty_setup',
      permissions: true
    }
  ],
  lease: [
    {
      type: 'link',
      to: Routes.LEASE_TENANTS,
      ico: 'TenantsIcon',
      name: 'tenants',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.LEASE_ORGANIZATIONS,
      ico: 'OrganizationsIcon',
      name: 'organizations',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.LEASE_AMENITIES,
      ico: 'Amenities',
      name: 'amenities',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.BOOKING_ORDERS,
      ico: 'BookingIcon',
      name: 'Booking',
      permissions: true
    }
  ],
  marketing: [
    {
      type: 'link',
      to: Routes.MARKETING_CALENDAR,
      ico: 'CalendarIcon',
      name: 'calendar',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.MARKETING_NEWS,
      ico: 'NewsIcon',
      name: 'news',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.MARKETING_EVENTS,
      ico: 'EventsIcon',
      name: 'events',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.MARKETING_SALE,
      ico: 'SalesIcon',
      name: 'sale',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.MARKETING_BLOG_LIST,
      ico: 'BlogsIcon',
      name: 'blogs',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.MARKETING_STORIES,
      ico: 'VideoIcon',
      name: 'stories.stories',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SURVEY,
      ico: 'ServeyIcon',
      name: 'survey.title',
      permissions: true
    }
  ],
  omnichannel: [
    {
      type: 'link',
      to: Routes.OMNICHANNEL_DASHBOARD,
      ico: 'ReportsIcon',
      name: 'dashboard',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_CUSTOMERS,
      ico: 'ProfileIcon',
      name: 'customer',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_AUDIENCES,
      ico: 'AudienceIcon',
      name: 'audience',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_SEGMENTATION,
      ico: 'Tags',
      name: 'segmentation',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_PUSH,
      ico: 'PushIcon',
      name: 'push_notifications',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_IN_APP_CAMPAIGN,
      ico: 'MessagesIcon',
      name: 'omnichannel_campaigns.in-app.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_SMS_CAMPAIGN,
      ico: 'SmsIcon',
      name: 'omnichannel_campaigns.sms.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_LIST,
      ico: 'DigitalSignageIcon',
      name: 'digital_signage_settings.campaign.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_EMAIL,
      ico: 'MailIcon',
      name: 'newsletter',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_AUTOMATIONS,
      ico: 'ElectricityIcon',
      name: 'automations.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_TRACKING_LINKS,
      ico: 'TrackingIcon',
      name: 'tracking.tracking',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.OMNICHANNEL_NEWSLETTER_SUBSCRIBERS,
      ico: 'MailIcon',
      name: 'newsletter_settings',
      permissions: true
    }
  ],
  communication: [
    {
      type: 'link',
      to: Routes.COMMUNICATION_CHAT,
      ico: 'MessagesIcon',
      name: 'chat.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.COMMUNICATION_SUPPORT,
      ico: 'SupportIcon',
      name: 'support.support_page_in_menu',
      permissions: true
    }
  ],
  assets: [
    {
      type: 'link',
      to: Routes.ASSETS_FLORPLAN_FLOORPLANS,
      ico: 'FloorplanIcon',
      name: 'floorplan',
      permissions: false
    }
  ],
  settings: [
    {
      type: 'link',
      to: Routes.SETTINGS_PROFILE,
      ico: 'ProfileIcon',
      name: 'my_profile',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.SETTINGS_GENERAL,
      ico: 'GeneralIcon',
      name: 'general',
      permissions: false
    },
    {
      type: 'link',
      to: Routes.SETTINGS_TEAM,
      ico: 'AdminIcon',
      name: 'team',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_FAQ,
      ico: 'FaqIcon2',
      name: 'faq',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.DOCUMENTATION,
      ico: 'DocumentsIcon',
      name: 'documentation.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_EXPORT,
      ico: 'DownloadIcon',
      name: 'export',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.FILES_LIST,
      ico: 'ImageIcon',
      name: 'files',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_GALLERIES,
      ico: 'ImageIcon',
      name: 'galleries.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.WEB_CUSTOMIZATION,
      ico: 'WebsiteIcon',
      name: 'website_customization_menu',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.MOBILE_CUSTOMIZATION,
      ico: 'PhoneIcon',
      name: 'mobile_customization_menu',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.KIOSK_CUSTOMIZATION,
      ico: 'ScreenIcon',
      name: 'kiosk_customization_menu',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.ALL_CUSTOMIZATION,
      ico: 'GeneralIcon',
      name: 'all_customization_menu',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_WIFI,
      ico: 'SensorsIcon',
      name: 'WiFi',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.LOGS,
      ico: 'GeneralIcon',
      name: 'logs',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_EMAILS,
      ico: 'MailIcon',
      name: 'emails_settings',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_DIGITAL_SIGNAGE_DEVICE_LIST,
      ico: 'DigitalSignageIcon',
      name: 'digital_signage_settings.device.title',
      permissions: true
    },
    {
      type: 'link',
      to: Routes.SETTINGS_DIGITAL_SIGNAGE_LOCATION_LIST,
      ico: 'DigitalSignageIcon',
      name: 'digital_signage_settings.location.title',
      permissions: true
    }
  ]
})

export function filterMenu() {
  const authStore = useAuthStore()
  let permissions: { [key: string]: { [key: string]: boolean } }
  if (localStorage.getItem('permissions') === null) {
    permissions = authStore.$state.permissions.menu
  } else {
    permissions = JSON.parse(localStorage.getItem('permissions') as string).menu
  }
  for (const myCategory in secondaryMenu) {
    for (const item of secondaryMenu[myCategory]) {
      if (permissions[myCategory] && permissions[myCategory][item.name] !== undefined) {
        // Here just temp solution
        // item.permissions = permissions[myCategory][item.name]
        item.permissions = true
      }
    }
  }
}
export function filterPrimaryMenu() {
  const authStore = useAuthStore()
  let permissions: { [key: string]: { [key: string]: boolean } }
  if (localStorage.getItem('permissions') === null) {
    permissions = authStore.$state.permissions.menu
  } else {
    permissions = JSON.parse(localStorage.getItem('permissions') as string).menu
  }
  for (const item of primaryMenu) {
    if (permissions[item.name] !== undefined) {
      // Here just temp solution
      // item.permissions = permissions[item.name]._group
      item.permissions = true
    }
  }
}
