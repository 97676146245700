<template>
  <div v-if="hasName" class="c-icon" :class="[customClass, { 'c-icon__background': !!backgroundColor }]" :style="computedStyle">
    <component :is="iconComponent" :width="width" :height="height" :fill="color"></component>
  </div>
</template>
<script lang="ts" setup>
import { IconProps } from '@/types/icon/Icon.types'
import { useIcon } from '@/composebles/useIcon'
import { PropType, watch, shallowRef, computed } from 'vue'

const props = defineProps({
  name: {
    type: String as PropType<IconProps['name']>,
    required: true
  },
  width: {
    type: String as PropType<IconProps['width']>,
    default: '20'
  },
  height: {
    type: String as PropType<IconProps['height']>,
    default: '20'
  },
  customClass: {
    type: String as PropType<IconProps['class']>,
    default: ''
  },
  backgroundColor: {
    type: String as PropType<IconProps['backgroundColor']>
  },
  color: {
    type: String as PropType<IconProps['color']>,
    default: '#fff'
  }
})

const { getIconComponent } = useIcon()
const iconComponent = shallowRef(getIconComponent(props.name))

watch(
  () => props.name,
  () => (iconComponent.value = getIconComponent(props.name))
)

const computedStyle = computed(() => {
  return {
    backgroundColor: props.backgroundColor
  }
})

const hasName = computed(() => props.name && props.name.length > 0)
</script>
