<template>
  <v-form v-model="valid" @submit.prevent="submitForm()">
    <!-- TOKEN ERRORS -->
    <div class="error-message mb-3 text-md-center" v-if="!invitationToken" v-html="$t('invitation.missing-invitation-token')"></div>
    <div class="error-message mb-3 text-md-center" v-if="errors && errors.invitation_token">
      <div v-for="error in errors.invitation_token">{{ error }}</div>
    </div>

    <!-- PASSWORD -->
    <div class="input-wrapper">
      <v-text-field
        v-model="password"
        :class="`c-text-input`"
        :prepend-inner-icon="PasswordIcon"
        :append-inner-icon="showPassword ? EyeIcon : EyeSlashIcon"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('password:')"
        :placeholder="$t('your_password')"
        :dirty="true"
        autocomplete="new-password"
        @click:append-inner="showPassword = !showPassword"
      >
        <template v-slot:append-inner>
          <ValidIcon class="c-valid"></ValidIcon>
        </template>
        <!-- PASSWORD ERRORS -->
        <template #details>
          <div v-if="errors && errors.password" v-for="error in errors" class="error-message">{{ error }}</div>
        </template>
      </v-text-field>
    </div>

    <!-- SUBMIT BUTTON -->
    <div class="input-wrapper">
      <button :class="`submit-button ${valid ? '' : 'disabled'}`" type="submit" :disabled="!valid">{{ $t('invitation.login') }}</button>
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { useLocale } from 'vuetify'
import ValidIcon from '@/components/icons/ValidIcon.vue'
import PasswordIcon from '@/components/icons/PasswordIcon.vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import EyeSlashIcon from '@/components/icons/EyeSlashIcon.vue'
import { RestApi } from '@/controllers/RestApi'
import { Routes } from '@/imports'
const { t } = useLocale()
const router = useRouter()
const route = useRoute()
const appStore = useAppStore()
const authStore = useAuthStore()
const valid = ref()
const showPassword = ref(false)
const password = ref('')
const invitationToken = ref()
const errors = ref()

async function submitForm() {
  appStore.toggleLoading(true)

  try {
    const response = await RestApi.invitationLogin(invitationToken.value, password.value)
    authStore.login(response.data)
    router.push(Routes.LOGIN)
  } catch (error) {
    const responseError = error as { response: { data: { errors: string[] } } } | undefined
    if (responseError && responseError.response && responseError.response.data && responseError.response.data.errors) {
      errors.value = responseError.response.data.errors
    } else {
      errors.value = t('invitation.login-error')
    }
  }

  appStore.toggleLoading(false)
}

onMounted(() => {
  invitationToken.value = route.query.invitation_token
})
</script>
