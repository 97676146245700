<template>
  <div class="primary-sidebar">
    <div class="primary-sidebar__top-part">
      <RouterLink to="/" class="primary-sidebar__logo">
        <img v-if="mall?.image" :src="mall?.image" alt="Simplaq" />
        <img v-else src="@/assets/img/logo.svg" alt="Simplaq" />
      </RouterLink>
      <div v-for="(item, index) in primaryMenu" :key="index">
        <RouterLink
          v-if="item.permissions === true"
          :to="item.to"
          :class="`primary-sidebar__link ${item.name === category ? 'active' : ''}`"
        >
          <template v-if="item.to === Routes.COMMUNICATION_CHAT">
            <CoCoIcon v-if="isUnreadCountLoading" class="primary-sidebar__spinner" icon="mdi-loading" size="16" />
            <v-chip v-else size="x-small" color="red" flat class="primary-sidebar__chip">{{ formattedUnreadCount }}</v-chip>
          </template>
          <IconBase :name="item.ico"></IconBase>

          <v-tooltip activator="parent" location="end" content-class="black tooltip-right tooltip-main-sidebar">{{
            $t(item.name)
          }}</v-tooltip>
        </RouterLink>
      </div>
    </div>
    <div class="primary-sidebar__bottom-part">
      <RouterLink to="#" @click.prevent="profileMenu = !profileMenu" class="primary-sidebar__avatar user-info clean-link">
        <div class="user-info__avatar ma-0">
          <img v-if="authStore.user.picture" :src="authStore.user.picture" alt="avatar" />
          <div class="letters" v-if="!authStore.user.picture">
            {{ CustomerHelpers.createAvatar(authStore.user?.first_name as string, authStore.user?.last_name as string) }}
          </div>
        </div>
      </RouterLink>
      <ProfileMenu v-if="profileMenu" @open="profileMenu = $event" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconBase from '@/components/icons/IconBase.vue'
import ProfileMenu from '@/components/ui/sidebar/ProfileMenu.vue'
import { CoCoIcon } from '@simplaq/common-core-components'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { useRoute } from 'vue-router'
import { computed, ref, onBeforeMount } from 'vue'
import { CustomerHelpers, RestApi, Routes } from '@/imports'
import { useLocale } from 'vuetify'
import { primaryMenu, filterPrimaryMenu } from '@/controllers/MenuConfig'
import { useCompanyProfile } from '@/store/companyProfile'
import { useChatStore } from '@/store/chat'
import { storeToRefs } from 'pinia'
const appStore = useAppStore()
let { current } = useLocale()
const authStore = useAuthStore()
const profileMenu = ref(false)
const currentRoute = useRoute()
const { formattedUnreadCount, isUnreadCountLoading } = storeToRefs(useChatStore())
const { getUnreadCount } = useChatStore()
const category = computed(() => currentRoute.meta.category)
const companyProfile = useCompanyProfile()
const mall = ref()
current.value = authStore.user.lang.locale
onBeforeMount(() => {
  filterPrimaryMenu()
  getData()
})
const getData = async () => {
  try {
    // appStore.toggleLoading(true)
    const mallResponse = await RestApi.getMalls()
    if (mallResponse.data.status === 'success') {
      mall.value = mallResponse.data.data.data[0]
    }
    getUnreadCount()
  } catch (e) {
    appStore.setSnackbar(true, {
      type: 'error',
      title: 'error',
      text: 'try_again_later'
    })
  } finally {
    companyProfile.setMall(mall.value)
    // appStore.toggleLoading(false) // Note: this cannot be here because it destroys loading in all pages
  }
}
</script>
