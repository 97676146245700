<script lang="ts">
import close from '@/assets/img/alerts/close.svg'
import success from '@/assets/img/alerts/success.svg'
import error from '@/assets/img/alerts/error.svg'
import warning from '@/assets/img/alerts/warning.svg'
import regular from '@/assets/img/alerts/regular.svg'
export default {
  close,
  success,
  error,
  warning,
  regular
}
</script>
