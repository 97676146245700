import * as Sentry from '@sentry/vue'
// STORE
import { UserProfileAndPermissions, useAuthStore } from '@/store/auth'

import { createRouter, createWebHistory } from 'vue-router'
import { RestApi, Routes } from '@/imports'
import { AxiosResponse } from 'axios'

import DefaultLayout from '@/layouts/default/Default.vue'
import DefaultWideLayout from '@/layouts/default/Wide.vue'
import LoginLayout from '@/layouts/login/Login.vue'
import FullScreenLayout from '@/layouts/full-screen/FullScreenLayout.vue'
import LoginView from '@/views/login/LoginView.vue'
import InvitationLoginView from '@/views/login/InvitationLoginView.vue'
import { useTransformSupportedLanguages } from '@/composebles/useTransformSupportedLanguages'

// ROUTER
const routes = [
  { path: '/', redirect: Routes.REPORTS_DASHBOARD },
  {
    path: Routes.LOGIN,
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: LoginView,
        meta: {
          path_name: 'login',
          category: 'default',
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: Routes.INVITATION_LOGIN,
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'invitationLogin',
        component: InvitationLoginView,
        meta: {
          path_name: 'login',
          category: 'default',
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: Routes.REPORTS_DASHBOARD,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'reports_dashboard',
        component: () => import('@/views/reports/dashboard/DashboardView.vue'),
        meta: {
          path_name: 'dashboard',
          category: 'reports',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.REPORTS_HISTORICAL,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'historical_reports',
        component: () => import('@/views/reports/historical/HistoricalView.vue'),
        meta: {
          path_name: 'historical_reports',
          category: 'reports',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.REPORT_HISTORICAL + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'historical_report',
        component: () => import('@/views/reports/historical/ReportView.vue'),
        meta: {
          path_name: 'historical_report',
          category: 'reports',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_REWARDS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_rewards',
        component: () => import('@/views/loyalty/rewards/RewardsView.vue'),
        meta: {
          path_name: 'rewards',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_REWARDS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_new_rewards',
        component: () => import('@/views/loyalty/rewards/RewardsNewView.vue'),
        meta: {
          path_name: 'newrewards',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_REWARDS_TIER_NEW,
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'loyalty_tier_rewards',
        component: () => import('@/views/loyalty/rewards/CreateRewardsTiers.vue'),
        meta: {
          path_name: 'create-rewards-tier',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_REWARDS_TIER_EDIT + '/:id',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'edit-rewards-tier',
        component: () => import('@/views/loyalty/rewards/EditRewardsTiers.vue'),
        meta: {
          path_name: 'edit_tier_rewards',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_REWARDS_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'edit_rewards',
        component: () => import('@/views/loyalty/rewards/RewardsEditView.vue'),
        meta: {
          path_name: 'editrewards',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_RECEIPTS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_receipts',
        component: () => import('@/views/loyalty/receipts/ReceiptsView.vue'),
        meta: {
          path_name: 'receipts',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_RECEIPT + ':id',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'loyalty_receipt',
        component: () => import('@/views/loyalty/receipts/ReceiptDetail.vue'),
        meta: {
          path_name: 'receipt',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_RECEIPTS_LABELER + ':id',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'loyalty_receipts_labeler',
        component: () => import('@/views/loyalty/receipts/labeler/LabelView.vue'),
        meta: {
          path_name: 'receipt',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_AWARDS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_awards',
        component: () => import('@/views/loyalty/receipts/ReceiptsView.vue'),
        meta: {
          path_name: 'awards',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_COUPONS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_coupons',
        component: () => import('@/views/loyalty/coupons/CouponsView.vue'),
        meta: {
          path_name: 'coupons',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_COUPONS_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_edit_coupons',
        component: () => import('@/views/loyalty/coupons/CouponsEditView.vue'),
        meta: {
          path_name: 'edit',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_COUPONS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty-new',
        component: () => import('@/views/loyalty/coupons/CouponsNewView.vue'),
        meta: {
          path_name: 'new',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_COUPON + '/:id',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'loyalty-coupons-id',
        component: () => import('@/views/loyalty/coupons/CouponsSingle.vue'),
        meta: {
          path_name: 'id',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_DASHBOARD,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_dashboard',
        component: () => import('@/views/reports/dashboard/DashboardView.vue'),
        meta: {
          path_name: 'loyalty_dashboard',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOYALTY_SETUP,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_setup',
        component: () => import('@/views/loyalty/setup/Setup.vue'),
        meta: {
          path_name: 'loyalty_setup',
          category: 'loyalty',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_AUTOMATIONS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'automations',
        component: () => import('@/views/omnichannel/automations/List.vue'),
        meta: {
          path_name: 'automations.title',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_AUTOMATIONS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'automations_new',
        component: () => import('@/views/omnichannel/automations/Edit.vue'),
        meta: {
          path_name: 'automations_new',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_AUTOMATIONS_EDIT + ':id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'automations_edit',
        component: () => import('@/views/omnichannel/automations/Edit.vue'),
        meta: {
          path_name: 'automations_edit',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_CALENDAR,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'marketing_calendar',
        component: () => import('@/views/marketing/calendar/CalendarView.vue'),
        meta: {
          path_name: 'calendar',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_DASHBOARD,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_dashboard',
        component: () => import('@/views/reports/dashboard/DashboardOmnichannelView.vue'),
        meta: {
          path_name: 'omnichannel_dashboard',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_PUSH,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_new_push_notification',
        component: () => import('@/views/marketing/push/PushView.vue'),
        meta: {
          path_name: 'push_notifications',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_PUSH_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'push_notifications',
        component: () => import('@/views/marketing/push/PushNewView.vue'),
        meta: {
          path_name: 'new_push_notification',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_PUSH_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'edit_push_notification',
        component: () => import('@/views/marketing/push/PushEditView.vue'),
        meta: {
          path_name: 'edit_push_notification',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_TRACKING_LINKS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'links',
        component: () => import('@/views/omnichannel/tracking/TrackingView.vue'),
        meta: {
          path_name: 'links',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_TRACKING_LINKS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'links-new',
        component: () => import('@/views/omnichannel/tracking/links/TrackingLinksEdit.vue'),
        meta: {
          path_name: 'links-new',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_TRACKING_LINKS_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'links-edit',
        component: () => import('@/views/omnichannel/tracking/links/TrackingLinksEdit.vue'),
        meta: {
          path_name: 'links-edit',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_NEWS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/marketing/news/NewsView.vue'),
        meta: {
          path_name: 'news',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_SCENARIOS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'loyalty_scenarios',
        component: () => import('@/views/marketing/scenarios/ScenariosView.vue'),
        meta: {
          path_name: 'scenarios',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_SCENARIOS_NEW,
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'loyalty_new_scenarios',
        component: () => import('@/views/marketing/scenarios/ScenariosNewView.vue'),
        meta: {
          path_name: 'new-scenarios',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_BLOG_LIST,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'blog',
        component: () => import('@/views/marketing/blogs/List.vue'),
        meta: {
          path_name: 'blog-list',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_BLOG_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'blog-new',
        component: () => import('@/views/marketing/blogs/EditBlog.vue'),
        meta: {
          path_name: 'blog-new',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_BLOG_EDIT + ':id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'blog-edit',
        component: () => import('@/views/marketing/blogs/EditBlog.vue'),
        meta: {
          path_name: 'blog-edit',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_BLOG_EDIT + ':id/content',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'blog-edit-content',
        component: () => import('@/views/marketing/blogs/EditBlogContent.vue'),
        meta: {
          path_name: 'blog-edit-content',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_STORIES,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'stories',
        component: () => import('@/views/marketing/stories/List.vue'),
        meta: {
          path_name: 'stories',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_STORIES_EDIT + ':id',
        name: 'stories-edit',
        component: () => import('@/views/marketing/stories/EditStory.vue'),
        meta: {
          path_name: 'story',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_STORIES_NEW,
        name: 'stories-new',
        component: () => import('@/views/marketing/stories/EditStory.vue'),
        meta: {
          path_name: 'story',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_CUSTOMERS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_customers',
        component: () => import('@/views/people/customers/CustomersView.vue'),
        meta: {
          path_name: 'customers',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_CUSTOMER + '/:id',
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_customer_id',
        component: () => import('@/views/people/customers/CustomerDetail.vue'),
        meta: {
          path_name: 'customers',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_SEGMENTATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'segmentation',
        component: () => import('@/views/people/segmentation/Segmentation.vue'),
        meta: {
          path_name: 'segmentation',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_AUDIENCES,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'audience',
        component: () => import('@/views/people/customers/audience/AudienceView.vue'),
        meta: {
          path_name: 'audiences',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_NEW_AUDIENCE,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'new_audience',
        component: () => import('@/views/people/customers/audience/edit/CreateAudience.vue'),
        meta: {
          path_name: 'new_audience',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_AUDIENCE_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'edit_audience',
        component: () => import('@/views/people/customers/audience/edit/EditAudience.vue'),
        meta: {
          path_name: 'edit_audience',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_IN_APP_CAMPAIGN,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'in-app-campaign',
        component: () => import('@/views/omnichannel/campaigns/inApp/View.vue'),
        meta: {
          path_name: 'in-app-campaign-list',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_IN_APP_CAMPAIGN_EDIT + ':id',
        name: 'in-app-campaign-message',
        component: () => import('@/views/omnichannel/campaigns/inApp/Message.vue'),
        meta: {
          path_name: 'in-app-campaign-message',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_IN_APP_CAMPAIGN_NEW,
        name: 'in-app-campaign-new-message',
        component: () => import('@/views/omnichannel/campaigns/inApp/Message.vue'),
        meta: {
          path_name: 'in-app-campaign-new-message',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_LIST,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_digital_signage_campaign_list',
        component: () => import('@/views/omnichannel/digitalSignage/campaign/List.vue'),
        meta: {
          path_name: 'digital_signage.campaign.title',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_digital_signage_campaign_new',
        component: () => import('@/views/omnichannel/digitalSignage/campaign/Edit.vue'),
        meta: {
          path_name: 'campaign_new',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_DIGITAL_SIGNAGE_CAMPAIGN_EDIT + ':id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'omnichannel_digital_signage_campaign_edit',
        component: () => import('@/views/omnichannel/digitalSignage/campaign/Edit.vue'),
        meta: {
          path_name: 'campaign_edit',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_DEVICE_LIST,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_device_list',
        component: () => import('@/views/omnichannel/digitalSignage/device/List.vue'),
        meta: {
          path_name: 'digital_signage.device.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_DEVICE_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_device_new',
        component: () => import('@/views/omnichannel/digitalSignage/device/Edit.vue'),
        meta: {
          path_name: 'digital_signage.device.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_DEVICE_EDIT + ':id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_device_edit',
        component: () => import('@/views/omnichannel/digitalSignage/device/Edit.vue'),
        meta: {
          path_name: 'digital_signage.device.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_LOCATION_LIST,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_location_list',
        component: () => import('@/views/omnichannel/digitalSignage/location/List.vue'),
        meta: {
          path_name: 'digital_signage.location.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_LOCATION_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_location_new',
        component: () => import('@/views/omnichannel/digitalSignage/location/Edit.vue'),
        meta: {
          path_name: 'digital_signage.location.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_DIGITAL_SIGNAGE_LOCATION_EDIT + ':id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_digital_signage_location_edit',
        component: () => import('@/views/omnichannel/digitalSignage/location/Edit.vue'),
        meta: {
          path_name: 'digital_signage.location.title',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_SMS_CAMPAIGN,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'sms-campaign',
        component: () => import('@/views/omnichannel/campaigns/sms/View.vue'),
        meta: {
          path_name: 'sms-campaign-list',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_SMS_CAMPAIGN_EDIT + ':id',
        name: 'sms-campaign-message',
        component: () => import('@/views/omnichannel/campaigns/sms/Message.vue'),
        meta: {
          path_name: 'sms-campaign-message',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_SMS_CAMPAIGN_NEW,
        name: 'sms-campaign-new-message',
        component: () => import('@/views/omnichannel/campaigns/sms/Message.vue'),
        meta: {
          path_name: 'sms-campaign-new-message',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_EMAIL,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'newsletter',
        component: () => import('@/views/omnichannel/newsletter/NewsletterView.vue'),
        meta: {
          path_name: 'newsletter',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_NEWSLETTER_NEW,
        name: 'newsletter-new',
        component: () => import('@/views/omnichannel/newsletter/NewslettrerNew.vue'),
        meta: {
          path_name: 'newsletter-new',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_NEWSLETTER_EDIT + '/:id',
        name: 'newsletter-edit',
        component: () => import('@/views/omnichannel/newsletter/NewslettrerEdit.vue'),
        meta: {
          path_name: 'newsletter-edit',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_TEMPLATE,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'template',
        component: () => import('@/views/omnichannel/newsletter/NewsletterView.vue'),
        meta: {
          path_name: 'template',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_TEMPLATE_EDIT + '/:id',
        name: 'template-edit',
        component: () => import('@/views/omnichannel/template/TemplateEdit.vue'),
        meta: {
          path_name: 'template-edit',
          category: 'omnichannel',
          requiresAuth: true
        }
      },
      {
        path: Routes.OMNICHANNEL_TEMPLATE_NEW,
        name: 'template-new',
        component: () => import('@/views/omnichannel/template/TemplateNew.vue'),
        meta: {
          path_name: 'template-new',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.OMNICHANNEL_NEWSLETTER_SUBSCRIBERS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'subscribers',
        component: () => import('@/views/omnichannel/subscribers/SubscribersView.vue'),
        meta: {
          path_name: 'subscribers',
          category: 'omnichannel',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLORPLAN_CREATE_NEW_UNIT,
    component: DefaultWideLayout,
    children: [
      {
        path: '',
        name: 'FloorplanViewCreateNewUnit',
        component: () => import('@/views/assets/floorplan/FloorplanViewCreateNewUnit.vue'),
        meta: {
          path_name: 'FloorplanViewCreateNewUnit',
          category: 'assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLORPLAN_FLOORPLANS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'floorplans',
        component: () => import('@/views/assets/floorplan/FloorplanView.vue'),
        meta: {
          path_name: 'floorplans',
          category: 'assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLORPLAN_UNITS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'units',
        component: () => import('@/views/assets/floorplan/FloorplanView.vue'),
        meta: {
          path_name: 'units',
          category: 'assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLOORPLAN_UNIT_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'floorplan-unit-edit',
        component: () => import('@/views/assets/floorplan/TabUnitsEditUnit.vue'),
        meta: {
          path_name: 'floorplan-unit-edit',
          category: 'assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLORPLAN_BUILDER,
    component: FullScreenLayout,
    children: [
      {
        path: '',
        name: 'floorplanBuilder',
        component: () => import('@/views/assets/floorplan/FloorplanViewMapBuilderWrapper.vue'),
        meta: {
          path_name: 'floorplanBuilder',
          category: 'assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ASSETS_FLORPLAN_MALL,
    component: FullScreenLayout,
    children: [
      {
        path: '',
        name: 'floorplanMall',
        component: () => import('@/views/assets/floorplan/FloorplanViewKiosk.vue'),
        meta: {
          path_name: 'floorplanMall',
          category: 'assets',
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_PROFILE,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_profile',
        component: () => import('@/views/settings/profile/ProfileView.vue'),
        meta: {
          path_name: 'my_profile',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_GALLERIES,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_galleries',
        component: () => import('@/views/settings/galleries/GalleriesView.vue'),
        meta: {
          path_name: 'settings_galleries',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_GALLERIES_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_galleries_new',
        component: () => import('@/views/settings/galleries/GalleriesCreate.vue'),
        meta: {
          path_name: 'settings_galleries_new',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_GALLERIES_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_galleries_edit',
        component: () => import('@/views/settings/galleries/GalleriesCreate.vue'),
        meta: {
          path_name: 'settings_galleries_edit',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_TEAM,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_team',
        component: () => import('@/views/admin/users/AdminUsersView.vue'),
        meta: {
          path_name: 'settings_team',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_EXPORT,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'export',
        component: () => import('@/views/settings/export/ExportView.vue'),
        meta: {
          path_name: 'export-list',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_EVENTS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'marketing_events',
        component: () => import('@/views/marketing/events/EventsView.vue'),
        meta: {
          path_name: 'events',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_EVENTS_EDIT + '/:id',
        name: 'marketing_edit_events',
        component: () => import('@/views/marketing/events/EventsEditView.vue'),
        meta: {
          path_name: 'edit-events',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_EVENTS_NEW,
        name: 'marketing_new_events',
        component: () => import('@/views/marketing/events/EventsNewView.vue'),
        meta: {
          path_name: 'new-events',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_NEWS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'marketing_news',
        component: () => import('@/views/marketing/news/NewsView.vue'),
        meta: {
          path_name: 'news',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_NEWS_NEW,
        name: 'marketing_news_new',
        component: () => import('@/views/marketing/news/NewsNewView.vue'),
        meta: {
          path_name: 'news-new',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_NEWS_EDIT + '/:id',
        name: 'marketing_news_edit',
        component: () => import('@/views/marketing/news/NewsEditView.vue'),
        meta: {
          path_name: 'news-edit',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_SALE,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'marketing_sale',
        component: () => import('@/views/marketing/offers/OffersView.vue'),
        meta: {
          path_name: 'sale',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_SALE_NEW,
        name: 'marketing_sale_new',
        component: () => import('@/views/marketing/offers/OffersNewView.vue'),
        meta: {
          path_name: 'sale-new',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.MARKETING_SALE_EDIT + '/:id',
        name: 'marketing_sale_edit',
        component: () => import('@/views/marketing/offers/OffersEditView.vue'),
        meta: {
          path_name: 'sale-edit',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MARKETING_MOBILE_APP_SETTINGS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'mobile_app_settings',
        component: () => import('@/views/marketing/mobileApp/Settings.vue'),
        meta: {
          path_name: 'mobile_app_settings',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SURVEY,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'survey',
        component: () => import('@/views/marketing/survey/SurveyView.vue'),
        meta: {
          path_name: 'survey',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.SURVEY_NEW,
        name: 'survey_new',
        component: () => import('@/views/marketing/survey/SurveyNew.vue'),
        meta: {
          path_name: 'survey_new',
          category: 'marketing',
          requiresAuth: true
        }
      },
      {
        path: Routes.SURVEY_EDIT + '/:id',
        name: 'survey_edit',
        component: () => import('@/views/marketing/survey/SurveyEdit.vue'),
        meta: {
          path_name: 'survey_edit',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SURVEY_RESPONSES + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'survey_responses',
        component: () => import('@/views/marketing/survey/SurveyResponses.vue'),
        meta: {
          path_name: 'survey_responses',
          category: 'marketing',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_TENANTS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_tenants',
        component: () => import('@/views/lease/tenants/TenantsView.vue'),
        meta: {
          path_name: 'tenants',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_TENANT_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_tenant_new',
        component: () => import('@/views/lease/tenants/edit/CreateTenant.vue'),
        meta: {
          path_name: 'tenantnew',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_TENANT_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_tenant_edit',
        component: () => import('@/views/lease/tenants/edit/EditTenant.vue'),
        meta: {
          path_name: 'tenantedit',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_ORGANIZATIONS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_organizations',
        component: () => import('@/views/lease/organizations/OrganizationsView.vue'),
        meta: {
          path_name: 'organizations',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_ORGANIZATIONS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_organizations_new',
        component: () => import('@/views/lease/organizations/OrganizationEdit.vue'),
        meta: {
          path_name: 'organizations',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_ORGANIZATIONS_EDIT,
    component: DefaultLayout,
    children: [
      {
        path: ':id',
        name: 'lease_organizations_edit',
        component: () => import('@/views/lease/organizations/OrganizationEdit.vue'),
        meta: {
          path_name: 'organizations',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_GENERAL,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_general',
        component: () => import('@/views/settings/general/GeneralView.vue'),
        meta: {
          path_name: 'general',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_FAQ,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'settings_faq',
        component: () => import('@/views/settings/faq/FaqView.vue'),
        meta: {
          path_name: 'faq',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/icons',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'icons',
        component: () => import('@/views/icons.vue'),
        meta: {
          path_name: 'icons',
          category: 'settings',
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: Routes.ADMIN_USER_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'new_users',
        component: () => import('@/views/admin/users/edit/AdminEditUser.vue'),
        meta: {
          path_name: 'new_users',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ADMIN_USER + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'edit_users',
        component: () => import('@/views/admin/users/edit/AdminEditUser.vue'),
        meta: {
          path_name: 'edit_users',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.DOCUMENTATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'documentation',
        component: () => import('@/views/settings/legal/DocumentationView.vue'),
        meta: {
          path_name: 'documentation',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.WEB_CUSTOMIZATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'web_customization',
        component: () => import('@/views/settings/customization/web/WebCustomizationView.vue'),
        meta: {
          path_name: 'web_customization',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.MOBILE_CUSTOMIZATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'mobile_customization',
        component: () => import('@/views/settings/customization/mobile/MobileCustomizationView.vue'),
        meta: {
          path_name: 'mobile_customization',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.KIOSK_CUSTOMIZATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'kiosk_customization',
        component: () => import('@/views/settings/customization/kiosk/KioskCustomizationView.vue'),
        meta: {
          path_name: 'kiosk_customization',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.ALL_CUSTOMIZATION,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'all_customization',
        component: () => import('@/views/settings/customization/all/AllCustomizationView.vue'),
        meta: {
          path_name: 'all_customization',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LOGS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'logs',
        component: () => import('@/views/settings/logs/Logs.vue'),
        meta: {
          path_name: 'logs',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.FILES_LIST,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'files_list',
        component: () => import('@/views/settings/files/FilesView.vue'),
        meta: {
          path_name: 'files_list',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_WIFI,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'wifi',
        component: () => import('@/views/settings/wifi/WifiSettings.vue'),
        meta: {
          path_name: 'wifi',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_AMENITIES,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_amenities',
        component: () => import('@/views/sales/amenities/AmenitiesView.vue'),
        meta: {
          path_name: 'amenities',
          category: 'lease',
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: Routes.LEASE_AMENITIES_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_amenities_new',
        component: () => import('@/views/sales/amenities/edit/CreateAmenity.vue'),
        meta: {
          path_name: 'amenitiesnew',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.LEASE_AMENITIES_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'lease_amenities_edit',
        component: () => import('@/views/sales/amenities/edit/EditAmenity.vue'),
        meta: {
          path_name: 'amenitiesedit',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: Routes.BOOKING_SERVICES,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'services',
        component: () => import('@/views/sales/booking/BookingView.vue'),
        meta: {
          path_name: 'services',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_SERVICES_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'services-new',
        component: () => import('@/views/sales/booking/services/ServicesEdit.vue'),
        meta: {
          path_name: 'services-new',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_SERVICES_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'services-edit',
        component: () => import('@/views/sales/booking/services/ServicesEdit.vue'),
        meta: {
          path_name: 'services-edit',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: Routes.BOOKING_OPTIONS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'options',
        component: () => import('@/views/sales/booking/BookingView.vue'),
        meta: {
          path_name: 'options',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_OPTIONS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'options-new',
        component: () => import('@/views/sales/booking/options/OptionsEdit.vue'),
        meta: {
          path_name: 'options-new',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_OPTIONS_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'options-edit',
        component: () => import('@/views/sales/booking/options/OptionsEdit.vue'),
        meta: {
          path_name: 'options-edit',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_ORDERS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import('@/views/sales/booking/BookingView.vue'),
        meta: {
          path_name: 'orders',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_ORDERS_NEW,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'orders-new',
        component: () => import('@/views/sales/booking/orders/OrdersEdit.vue'),
        meta: {
          path_name: 'orders-new',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.BOOKING_ORDERS_EDIT + '/:id',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'orders-edit',
        component: () => import('@/views/sales/booking/orders/OrdersEdit.vue'),
        meta: {
          path_name: 'orders-edit',
          category: 'lease',
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: Routes.COMMUNICATION_CHAT,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'chat',
        component: () => import('@/views/chat/Chat.vue'),
        meta: {
          path_name: 'chat',
          category: 'communication',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.COMMUNICATION_SUPPORT,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'questions',
        component: () => import('@/views/communication/support/SupportView.vue'),
        meta: {
          path_name: 'questions',
          category: 'communication',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: Routes.SETTINGS_EMAILS,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'emails_settings',
        component: () => import('@/views/settings/email/EmailsSettings.vue'),
        meta: {
          path_name: 'emails_settings',
          category: 'settings',
          requiresAuth: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  // load settings if they are not yet loaded.
  if (authStore.settingsApi === null) {
    let countiesResponse
    try {
      countiesResponse = await RestApi.countires()
    } catch (error) {
      console.log(error)
    }

    try {
      const settingsResponse = await RestApi.commonSettings()
      const extendedSettingsResponse = await RestApi.extendedSettings()
      authStore.setSettingsApi({
        ...settingsResponse.data.data,
        ...extendedSettingsResponse.data.data,
        availableСountries: countiesResponse?.data?.data?.data ? [...countiesResponse.data.data.data] : [],
        availableLanguages: useTransformSupportedLanguages(settingsResponse.data.data.availableLanguages)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // logic below is needed for situations like 'page-refresh F5'
  // get user data, permissions, roles and access_token
  if (!authStore.authData && to.name !== 'login' && to.name !== 'invitationLogin') {
    try {
      const response: AxiosResponse<{
        httpCode: number
        httpStatus: string
        status: string
        data: UserProfileAndPermissions
      }> = await RestApi.getUserProfileAndPermissions()

      const userData = response.data.data

      switch (response.data.httpCode) {
        case 401:
          // unauthenticated
          if (response.data.status === 'error' && to.name === 'floorplanMall') {
            // Continue to 'public floorplan' route, user is not logged in, no user data are available.
            return next()
          } else {
            return next('/login')
          }
        case 200:
          if (response.data.status === 'error' && to.name === 'floorplanMall') {
            // Continue to 'public floorplan' route, user is not logged in, no user data are available.
            return next()
          } else {
            // save user data to store
            authStore.saveAuthData({
              access_token: userData.access_token,
              permissions: userData.permissions,
              role: { id: userData.role.id, name: userData.role.name, role: userData.role.role },
              user: {
                ...userData.user,
                // special mapping here as backend returns title and value for language but in the whole app we use different typing.
                lang: {
                  id: 0,
                  language: userData.user.lang.title as string,
                  title: userData.user.lang.title as string,
                  locale: userData.user.lang.value as string,
                  value: userData.user.lang.value as string,
                  order: 0
                }
              }
            })
            Sentry.setUser({
              id: userData.user.id === null ? 0 : userData.user.id,
              email: userData.user.email,
              username: `${userData.user.first_name} ${userData.user.last_name}`
            })

            return next()
          }
        default:
          // something went wrong
          console.error('Error:', response.data)
          return next('/login')
      }
    } catch (error) {
      // something went wrong
      console.log(error)
      return next('/login')
    }
  } else {
    next()
  }
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    window.location.assign(to.fullPath)
  }
})

export default router
