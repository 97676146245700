export enum PageCase {
  EVENTS = 'events',
  NEWS = 'news',
  NOTIFICATIONS = 'notifications',
  OFFERS = 'offer',
  REWARDS = 'rewards',
  COUPONS = 'coupons',
  COUPON = 'coupon',
  TENANT = 'tenant',
  SURVEY = 'survey'
}

export const ListOfNumberDays = ['5', '10', '20', '30', '50', '100']

// TODO: expand with other components.
export const TableFilterComponents = {
  RECEIPTS_LIST: 'receipts-list',
  NEWSLETTERS_LIST: 'newsletter',
  GENERIC_LIST: 'generic-list',
  BOOKING_LIST: 'booking-list',
  STORY_LIST: 'story-list',
  OMNICHANNEL_AUTOMATIONS: 'omnichannel-automations-list',
  DIGITAL_SIGNAGE_CAMPAIGN_LIST: 'DS-campaign-list',
  DIGITAL_SIGNAGE_DEVICE_LIST: 'DS-device-list',
  DIGITAL_SIGNAGE_LOCATION_LIST: 'DS-location-list',
  SURVEY_LIST: 'survey-list',
  GALLERY_LIST: 'gallery-list',
  TEMPLATES_LIST: 'templates-list',
  FLOORPLAN_UNITS_LIST: 'floorplan-units-list'
}

export const EditOptions = ['edit', 'information']

/**
 * Settings for FileUploader
 * List of allowed file types from backend config. If not specified, all file types are allowed.
 */
export const allowedFileTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'image/bmp',
  'image/x-bmp',
  'image/x-bitmap',
  'image/x-xbitmap',
  'image/x-win-bitmap',
  'image/x-windows-bmp',
  'image/ms-bmp',
  'image/x-ms-bmp',
  'application/bmp',
  'application/x-bmp',
  'application/x-win-bitmap',
  'image/gif',
  'image/jpx',
  'image/jpm',
  'image/pjpeg',
  'image/x-png',
  'image/jp2',
  'image/x-icon',
  'image/x-ico',
  'image/vnd.microsoft.icon',
  'image/tiff',
  'image/webp',
  'video/3gpp2',
  'video/3gp',
  'video/3gpp',
  'video/x-msvideo',
  'video/msvideo',
  'video/avi',
  'video/x-flv',
  'video/x-f4v',
  'video/mj2',
  'video/quicktime',
  'video/x-sgi-movie',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/x-ms-wmv',
  'video/x-ms-asf',
  'application/octet-stream',
  'application/excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/x-comma-separated-values',
  'text/comma-separated-values',
  'application/vnd.msexcel',
  'application/zip',
  'application/x-rar-compressed',
  'application/vnd.rar',
  'application/x-rar',
  'application/octet-stream',
  'application/x-compressed',
  'application/x-7z-compressed',
  'application/x-tar',
  'application/gzip',
  'application/x-bzip2',
  'image/svg+xml'
]

/**
 * Most commonly used aspect ratios. Used in customization pages.
 */
import { AspectRatios } from '@/types/pages/customization/Customization.types'

export const commonAspectRatios: AspectRatios[] = [
  { ratio: '16/9', value: 16 / 9, width: 1920, height: 1080, exampleName: 'Full HD' },
  { ratio: '9/16', value: 9 / 16, width: 1080, height: 1920, exampleName: 'Vertical Video' },
  { ratio: '16/10', value: 16 / 10, width: 1920, height: 1200, exampleName: 'WUXGA' },
  { ratio: '10/16', value: 10 / 16, width: 1200, height: 1920, exampleName: 'Vertical WUXGA' },
  { ratio: '4/3', value: 4 / 3, width: 1024, height: 768, exampleName: 'XGA' },
  { ratio: '3/4', value: 3 / 4, width: 768, height: 1024, exampleName: 'Portrait XGA' },
  { ratio: '1/1', value: 1, width: 1080, height: 1080, exampleName: 'Square' },
  { ratio: '21/9', value: 21 / 9, width: 3440, height: 1440, exampleName: 'Ultrawide' },
  { ratio: '9/21', value: 9 / 21, width: 1440, height: 3440, exampleName: 'Vertical Ultrawide' },
  { ratio: '3/2', value: 3 / 2, width: 3000, height: 2000, exampleName: 'Digital Camera' },
  { ratio: '2/3', value: 2 / 3, width: 2000, height: 3000, exampleName: 'Portrait Digital Camera' },
  { ratio: '5/4', value: 5 / 4, width: 1280, height: 1024, exampleName: 'SXGA' },
  { ratio: '4/5', value: 4 / 5, width: 1080, height: 1350, exampleName: 'Instagram Portrait' },
  { ratio: '2.35/1', value: 2.35, width: 2560, height: 1090, exampleName: 'Cinemascope' },
  { ratio: '1.85/1', value: 1.85, width: 1920, height: 1038, exampleName: 'Widescreen' },
  { ratio: '2/1', value: 2, width: 2000, height: 1000, exampleName: 'Univisium' },
  { ratio: '1.43/1', value: 1.43, width: 2048, height: 1430, exampleName: 'IMAX' },
  { ratio: '3/1', value: 3, width: 3000, height: 1000, exampleName: 'Panoramic' },
  { ratio: '5/3', value: 5 / 3, width: 1500, height: 900, exampleName: 'HD+' },
  { ratio: '7/5', value: 7 / 5, width: 1400, height: 1000, exampleName: 'Historical Photography' }
]

export const emailStatuses = [
  {
    title: 'status_draft',
    value: 0
  },
  {
    title: 'status_sending',
    value: 1
  },
  {
    title: 'status_sent',
    value: 2
  },
  {
    title: 'status_scheduled',
    value: 3
  },
  {
    title: 'status_archived',
    value: 4
  }
]

export const surveyStatuses = [
  {
    title: 'status_archived',
    value: 0
  },
  {
    title: 'status_published',
    value: 1
  },
  {
    title: 'status_draft',
    value: 2
  }
]

export const galleryStatus = [
  {
    title: 'status_archived',
    value: 0
  },
  {
    title: 'status_published',
    value: 1
  }
]

export const templateStatus = [
  {
    title: 'status_draft',
    value: 0
  },
  {
    title: 'status_published',
    value: 1
  },
  {
    title: 'status_archived',
    value: 2
  }
]
