/**
 * main.ts
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Import 3rd party dependencies
import { createApp } from 'vue'

import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { createI18n, useI18n } from 'vue-i18n'
import VueApexCharts from 'vue3-apexcharts'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import VueDatePicker from '@vuepic/vue-datepicker'
import Pusher from 'pusher-js'
import * as Sentry from '@sentry/vue'

import '@vuepic/vue-datepicker/dist/main.css'
import '@mdi/font/css/materialdesignicons.css'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})
// @ts-ignore
import Vu3PhoneNumberInput from 'vue3-phone-number-input'

// Languages
import us from '@/locales/en.json'
import sk from '@/locales/sk.json'
import cz from '@/locales/cs.json'

// Controllers
import { RestApi } from '@/controllers/RestApi'

// Fonts
import { loadFonts } from '@/plugins/webfontloader'

// Pinia
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Router
import router from '@/router/index'

// Kanva
import VueKonva from 'vue-konva'

// Styles
import 'vuetify/styles'
import 'vue3-phone-number-input/dist/vue3-phone-number-input.css'
import '@/styles/main.scss'

// Import global components
import CountryFlag from 'vue-country-flag-next'
import AssetsIcon from '@/components/icons/AssetsIcon.vue'

const i18n: any = createI18n({
  legacy: false,
  locale: 'us',
  fallbackLocale: 'us',
  allowComposition: true,
  messages: {
    us,
    sk,
    cz
  },
  fallbackWarn: false,
  silentFallbackWarn: true,
  missingWarn: false
})

const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1867C0',
          secondary: '#5CBBF6'
        }
      }
    }
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  }
})

// For testing, ENV values are displayed only in development. MODE is displayed discreetly otherwise
if (import.meta.env.MODE == 'development') {
  const urls = {
    'Pusher API key': import.meta.env.VITE_PUSHER_APP_KEY,
    'Pusher cluster': import.meta.env.VITE_PUSHER_CLUSTER,
    'baseURL': import.meta.env.VITE_BASE_URL,
    'Origin': import.meta.env.VITE_API_ORIGIN,
    'tenantCentralDomain': import.meta.env.VITE_TENANT_CENTRAL_DOMAIN,
    'proxyTarget': import.meta.env.VITE_PROXY_TARGET
  }

  console.log(
    '[dev debug]:',
    Object.entries(urls)
      .map(([key, value]) => `${key}: ${value}`)
      .join(' | ')
  )
} else {
  console.log(
    'Mode:',
    import.meta.env.MODE === 'production'
      ? 'p'
      : import.meta.env.MODE === 'staging'
        ? 's'
        : import.meta.env.MODE === 'beta'
          ? 'b'
          : import.meta.env.MODE
  )
}

const pusher: Pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
  cluster: import.meta.env.VITE_PUSHER_CLUSTER
})

const app = createApp(App)

// Load app fonts
loadFonts()

// Global components
app.component('AssetsIcon', AssetsIcon)
app.component('VueDatePicker', VueDatePicker)
app.component('country-flag', CountryFlag)
app.provide('Pusher', pusher)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const tenantFromEnvOrHost = (hostname: string): string | null => {
  if (hostname.includes('localhost')) return null
  else {
    const match = hostname.match(/^(?:www\.)?([^.]+)\./)
    return match ? match[1] : null
  }
}
const tenantOnly = tenantFromEnvOrHost(
  import.meta.env.VITE_API_ORIGIN ? new URL(import.meta.env.VITE_API_ORIGIN).hostname : window.location.hostname
)

if (['staging', 'production'].includes(import.meta.env.MODE)) {
  Sentry.init({
    app,
    dsn: 'https://8cbc77db357bf3e7166648309fb6708d@sentry.mysimplaq.com/3',
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayCanvasIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    initialScope: {
      tags: { tenant: tenantOnly }
    }
  })
}

// mount
app.use(pinia).use(router).use(i18n).use(vuetify).use(Vu3PhoneNumberInput).use(VueKonva).use(VueApexCharts).mount('#app')

// rest API Initialization - this needs to happen after app initialization
RestApi.init()
RestApi.initAxiosWebsiteInstance()

export { app }
