<template>
  <v-snackbar v-model="snackbar.show" max-width="343" class="c-snackbar" :class="snackbar.content.type">
    <div class="c-snackbar-content">
      <div class="c-snackbar-content__ico" v-if="snackbar.content.type">
        <img :src="alertsIcons[snackbar.content.type]" class="alert-ico" />
      </div>
      <div class="c-snackbar-content__text">
        <div class="title" v-if="snackbar.content.title">
          {{ $t(snackbar.content.title) }}
        </div>
        <div v-if="snackbar.content.text">
          {{ $t(snackbar.content.text) }}
        </div>
      </div>
    </div>
    <template v-slot:actions>
      <button @click="snackbar.show = false">
        <img :src="alertsIcons.close" />
      </button>
    </template>
  </v-snackbar>
</template>
<script lang="ts" setup>
import alertsIcons from '@/assets/img/alerts/alertsIcons.vue'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
const appStore = useAppStore()
const { snackbar } = storeToRefs(appStore)
</script>
