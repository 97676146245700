import { CalendarCount, PushItem, RestApiPaginatedResponseItem, RestApiResponseItem, RewardStockStatus, Routes, Unit } from '@/imports'
import router from '@/router'
import Axios, { AxiosResponse } from 'axios'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { MessagesOverviewResponse, SendMessageResponse, ThreadMessagesResponse } from '@/types/chat/Chat.types'
import { IBlog } from '@/types/pages/blog/Blog.types'
import { Message as InAppMessage } from '@/types/pages/campaigns/InApp.types'
import { Message as SMSMessage } from '@/types/pages/campaigns/SMS.types'
import { LogData } from '@/composebles/useLogging'
import { Story } from '@/types/pages/story/story.types'
import { WiFiSettings } from '@/views/settings/wifi/WifiSettings.vue'
import { Campaign as LoyaltyCampaign } from '@/types/pages/campaigns/Loyalty.types'
import { Campaign } from '@/types/pages/digitalSignage/Campaign.types'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'

export class RestApi {
  // instance to call TENANT.website.mysimplaq.com APIs.
  public static axiosWebsiteInstance: ReturnType<typeof Axios.create> | null = null

  public static axios = Axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })

  public static async tenantExists(tenantId: string) {
    if (tenantId !== 'localhost:3000') {
      try {
        await this.axios.get(`/api/tenants/${tenantId}/exists`)
      } catch (error) {
        const appStore = useAppStore()
        appStore.setTenantId(tenantId as never)
        appStore.setSnackbar(true, {
          type: 'error',
          title: 'error',
          text: 'url_error_tenant'
        })
        console.error('Error checking tenant existence:', error)
      }
    }
  }
  public static sendMessage(
    customerId: number | string,
    userId: number,
    threadId: number,
    message: string,
    isAnswer: boolean,
    file?: File | null
  ): Promise<AxiosResponse<SendMessageResponse>> {
    const data = {
      customerId,
      userId,
      threadId,
      message,
      file,
      isAnswer
    }
    if (!file) delete data.file
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]: [any, any]) => {
      formData.set(`${key}`, value)
    })
    return this.axios.post('/api/messages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  public static editMessage(messageId: number, message: string): Promise<AxiosResponse<SendMessageResponse>> {
    return this.axios.patch('/api/messages', { messageId, message })
  }
  public static getThreadMessages(
    customerId: number | string,
    threadId: number,
    controller: AbortController
  ): Promise<AxiosResponse<ThreadMessagesResponse>> {
    return this.axios.post(
      '/api/messages/message',
      {
        customerId,
        threadId,
        isCustomer: false
      },
      {
        signal: controller.signal
      }
    )
  }
  public static changeThreadStatus(
    userId: number,
    customerId: number | string,
    threadId: number,
    threadStatus: boolean
  ): Promise<AxiosResponse<any>> {
    return this.axios.patch('/api/messages/status', {
      userId,
      customerId,
      threadId,
      threadStatus
    })
  }
  public static markAsRead(
    customerId: number | string,
    userId: number,
    threadId: number,
    messageId: number,
    isCustomer: boolean
  ): Promise<AxiosResponse<any>> {
    return this.axios.patch('/api/messages/message/read', {
      userId,
      customerId,
      threadId,
      messageId,
      isCustomer
    })
  }
  public static getMessagesOverview(customerId?: string | number): Promise<AxiosResponse<MessagesOverviewResponse>> {
    return this.axios.post('/api/messages/all', { isCustomer: false, customerIds: customerId ? [customerId] : [] })
  }
  public static getTotalUnreadCount() {
    return this.axios.get('/api/messages/unread-messages/count')
  }

  public static async init() {
    const tenancy = RestApi.getCurrentTenantInfo()
    const authStore = useAuthStore()
    this.axios.interceptors.request.use(
      function (config) {
        const tenancy = RestApi.getCurrentTenantInfo()
        if (!tenancy.isCentralDomain) {
          config.headers['X-Tenant'] = tenancy.subdomain
        }

        config.headers['Accept-Language'] = 'en'

        const access_token = localStorage.getItem('access_token')
        const user_group = localStorage.getItem('user_group')

        if (access_token) {
          config.headers.Authorization = `Bearer ${access_token}`
          /*
          User groups
          1 Portal administrator
          2 Manager
          3 Service account
          4 Content administrator
          5 Guest
          7 Receipt manager
          */
        } else if (user_group ? parseInt(user_group) === 5 : authStore.getUserGroup === 5) {
          // guest
          return config
        } else {
          router.push(Routes.LOGIN)
        }

        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )
    this.axios.interceptors.response.use(
      function (response) {
        if (response.data.httpCode === 401 && response.data.httpStatus === 'Unauthorized') {
          authStore.clearAuthToken()
          router.push(Routes.LOGIN)
          return Promise.reject(response.data.httpStatus)
        } else {
          return response
        }
      },
      function (error) {
        const appStore = useAppStore()
        appStore.toggleLoading(false)
        if (error.response.status === 401) {
          // unauthorized
          authStore.clearAuthToken()
          router.push(Routes.LOGIN)
        }
        return Promise.reject(error)
      }
    )
    await this.tenantExists(tenancy.fulldomain as never)
  }

  public static async initAxiosWebsiteInstance() {
    if (this.axiosWebsiteInstance) {
      // already exist
      return this.axiosWebsiteInstance
    }

    let baseURL!: string
    const tenancy = RestApi.getCurrentTenantInfo()
    const urlPrefix = tenancy.subdomain as string
    const urlSuffix =
      import.meta.env.MODE === 'beta' || import.meta.env.MODE === 'development' ? '.website.beta.mysimplaq.com' : '.website.mysimplaq.com'

    if (import.meta.env.MODE === 'development') {
      // development mode
      baseURL = `http://${urlPrefix}`
    } else {
      // production / staging / beta
      baseURL = `https://${urlPrefix}${urlSuffix}`
    }

    this.axiosWebsiteInstance = Axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }

  // this method returns current tenant for all API requests
  private static getCurrentTenantInfo(): { fulldomain: string | null; subdomain: string | null; isCentralDomain: boolean } {
    const isCentralDomain = process.env.tenantCentralDomain === window.location.hostname
    const tenantInfo: { fulldomain: string | null; subdomain: string | null; isCentralDomain: boolean } = {
      fulldomain: null,
      subdomain: null,
      isCentralDomain
    }
    if (!isCentralDomain) {
      // https://galerieharfa.mysimplaq.com/ -> ['galerieharfa', 'mysimplaq', 'com']
      tenantInfo.fulldomain = window.location.host
      tenantInfo.subdomain = window.location.host.split('.')[0]
    }
    return tenantInfo
  }
  public static deleteEntity(id: number) {
    return this.axios.delete('/api/entities/' + id)
  }
  public static updateEntity(params: { id: number; data: any }) {
    return this.axios.put('/api/entities/' + params.id, params.data)
  }
  public static createNewEntity(data: any) {
    return this.axios.post('/api/entities', data)
  }
  public static duplicateEntity(params: { id: number; includeChildren: boolean }) {
    return this.axios.post('/api/entities/' + params.id + '/clone?with_children=' + params.includeChildren)
  }
  public static getUnits() {
    return this.axios.get(`/api/units`)
  }
  public static getUnitsPaginated(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/units/paginated?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getUnitById(id: number) {
    return this.axios.get('/api/units/' + id)
  }
  public static saveUnit(id: number, data: Omit<Unit, 'company'> & { company_id: number | null }) {
    return this.axios.put('/api/units/' + id, data)
  }
  public static deleteUnit(id: number) {
    return this.axios.delete('/api/units/' + id)
  }
  public static getCompanies() {
    return this.axios.get('/api/companies?per_page=500')
  }
  public static async getCompaniesForDropdown() {
    return await this.axios.get('/api/companies/dropdown?per_page=500')
  }
  public static async getLocationGeometry(location: string) {
    return await this.axios.get(`https://nominatim.openstreetmap.org/search?format=json&polygon_geojson=1&limit=5&q=${location}`)
  }
  public static async getImage(url: string) {
    return await this.axios.get(`/img${url}`, { responseType: 'blob' })
  }
  public static async getEntities(params: { format: string; forEditor: boolean }) {
    return await this.axios.get(`/api/entities?format=${params.format}&forEditor=${params.forEditor}`)
  }
  public static async getEntity(params: { format: string; id: number }) {
    return await this.axios.get(`/api/entities/${params.id}?format=${params.format}`)
  }
  public static login(email: string, password: string) {
    const data = new FormData()
    data.set('email', email)
    data.set('password', password)
    return this.axios.post('/api/login', data)
  }
  public static invitationLogin(invitation_token: string, password: string) {
    const data = new FormData()
    data.set('password', password)
    data.set('invitation_token', invitation_token)
    return this.axios.post('/api/login/invitation', data)
  }
  public static logout() {
    return this.axios.get('/api/logout')
  }

  public static getCompanyCategories() {
    return this.axios.get('/api/company-cats?per_page=100')
  }

  // Dashboard
  public static getDashboard(period: string) {
    return this.axios.get(`/api/dashboard${period}`)
  }
  // Calendar
  public static fetchCalendar(filter: string) {
    return this.axios.get(`/api/calendar?${filter}`)
  }
  public static fetchEventCount(): Promise<AxiosResponse<RestApiResponseItem<CalendarCount>>> {
    return this.axios.get<RestApiResponseItem<CalendarCount>>(`/api/calendar/count`)
  }
  public static archiveEvent(id: number) {
    return this.axios.patch(`/api/events/archive/${id}`)
  }
  public static changeNewsStatus(id: number, status: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch(`/api/news/status/${id}`, { status: status })
  }
  public static removeEventImage(id: number) {
    return this.axios.delete(`/api/events/image/${id}`)
  }
  //Countries
  public static countires() {
    return this.axios.get('/api/countries')
  }
  // Settings
  public static commonSettings() {
    return this.axios.get('/api/settings')
  }
  public static extendedSettings() {
    return this.axios.get('/api/settings/extended')
  }
  public static getLocalizationWeb(lang: string | null = 'gb') {
    return this.axios.get(`/api/web-app/v1/translations/web/${lang}`)
  }
  public static updateLocalizationWeb(data: Object) {
    return this.axios.put(`/api/translations/web`, data)
  }
  public static getLocalizationMobile(lang: string | null = 'gb') {
    return this.axios.get(`/api/web-app/v1/translations/mobile-app/${lang}`)
  }
  public static updateLocalizationMobile(data: Object) {
    return this.axios.put(`/api/translations/mobile-app`, data)
  }
  public static getLocalizationKiosk(lang: string | null = 'gb') {
    return this.axios.get(`/api/web-app/v1/translations/kiosk/${lang}`)
  }
  public static updateLocalizationKiosk(data: Object) {
    return this.axios.put(`/api/translations/kiosk`, data)
  }
  public static getSettingsWeb(group: string, perPage: number = 1000, type: string) {
    return this.axios.get(`/api/settings-web-app?filter[group]=${group}&per_page=${perPage}&filter[type]=${type}`)
  }
  public static addSettingsWeb(data: Object) {
    return this.axios.post(`/api/settings-web-app`, data)
  }
  public static updateSettingsWeb(data: Object) {
    return this.axios.put(`/api/settings-web-app`, data)
  }
  public static getSettingsWiFi() {
    return this.axios.get(`/api/wifi-settings`)
  }
  public static updateSettingsWiFi(data: WiFiSettings[]) {
    return this.axios.post(`/api/wifi-settings`, { settings: data })
  }
  public static deleteSettingsParameter(parameterId: number) {
    return this.axios.delete(`/api/settings-web-app/${parameterId}`)
  }
  public static getSettingsOptions(optionName: string) {
    return this.axios.get(`/api/options/${optionName}`)
  }
  public static changeSettingsOptions(data: any) {
    return this.axios.post(`/api/options`, data)
  }
  // Rewards
  public static fetchRewards(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/rewards?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static fetchRewardsById(id: number) {
    return this.axios.get('/api/rewards/' + id)
  }
  public static getRewardStockStatus(id: number): Promise<AxiosResponse<RestApiResponseItem<RewardStockStatus>>> {
    return this.axios.get('/api/rewards/' + id + '/stock')
  }
  public static deleteRewards(id: number) {
    return this.axios.delete('/api/rewards/' + id)
  }
  public static archiveRewards(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/rewards/archive/' + id)
  }
  public static publishRewards(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/rewards/publish/' + id)
  }
  public static editRewardsById(id: number, event: {}) {
    return this.axios.put('/api/rewards/' + id, event)
  }
  public static createRewards(event: any) {
    return this.axios.post('/api/rewards', event)
  }
  public static changeStatusRewards(id: number, status: {}): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/rewards/status/' + id, status)
  }
  public static fetchRewardsRedeemed(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/rewards/redeems?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static forceRewardsRedeemed(reward_id: string, customer_id: string) {
    return this.axios.patch(`/api/customer-rewards/force-redeem-reward/${reward_id}/${customer_id}`)
  }
  public static forceRewardsReserved(customer_reward_id: number) {
    return this.axios.patch(`/api/customer-rewards/force-redeem-reserved-reward/${customer_reward_id}`)
  }
  public static forceRewardsReactivate(customer_reward_id: number) {
    return this.axios.patch(`/api/customer-rewards/force-redeem-canceled-reward/${customer_reward_id}`)
  }
  public static forceRewardsCancel(id: number) {
    return this.axios.patch(`/api/customer-rewards/manual-cancel/${id}`)
  }

  // Notifications
  public static fetchNotifications(
    page: number = 1,
    perPage: number = 10,
    filter: string,
    sort: string
  ): Promise<AxiosResponse<RestApiPaginatedResponseItem<PushItem>>> {
    return this.axios.get(`/api/notifications?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static fetchNotificationsById(id: number) {
    return this.axios.get('/api/notifications/' + id)
  }

  public static createNotifications(event: any) {
    return this.axios.post('/api/notifications', event)
  }

  public static editNotifications(id: number, event: {}) {
    return this.axios.put('/api/notifications/' + id, event)
  }

  public static changeStatusNotifications(id: number, status: {}) {
    return this.axios.put('/api/notifications/status/' + id, status)
  }

  public static deleteNotifications(id: number) {
    return this.axios.delete('/api/notifications/' + id)
  }

  public static getNotificationsOverview(query: string) {
    return this.axios.get(`/api/notifications/statistics?${query}`)
  }

  // News
  public static fetchNews(
    page: number = 1,
    perPage: number = 10,
    filter: string,
    sort: string
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.get(`/api/news?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static fetchNewsById(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.get('/api/news/' + id)
  }
  public static deleteNews(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.delete('/api/news/' + id)
  }
  public static editNewsById(id: number, event: {}): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.put('/api/news/' + id, event)
  }
  public static createNews(event: any): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.post('/api/news', event)
  }
  public static changeStatusNews(id: number, status: {}): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/news/status/' + id, status)
  }
  public static removeNewsImage(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.delete(`/api/news/image/${id}`)
  }

  public static featureNews(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch(`/api/news/sort/${id}`)
  }

  public static unfeatureNews(): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.delete(`/api/news/sort`)
  }

  // Events
  public static fetchEvents(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/events?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static fetchEventById(id: number) {
    return this.axios.get('/api/events/' + id)
  }
  public static deleteEvent(id: number) {
    return this.axios.delete('/api/events/' + id)
  }
  public static editEventById(id: number, event: {}) {
    return this.axios.put('/api/events/' + id, event)
  }
  public static createEvent(event: {}) {
    return this.axios.post('/api/events', event)
  }
  public static changeStatusEvent(id: number, status: {}): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/events/status/' + id, status)
  }
  public static featureEvent(id: number) {
    return this.axios.patch(`/api/events/sort/${id}`)
  }
  public static unfeatureEvent() {
    return this.axios.delete(`/api/events/sort`)
  }
  // Offers
  public static fetchOffers(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/products?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static fetchOfferById(id: number) {
    return this.axios.get('/api/products/' + id)
  }
  public static deleteOffer(id: number) {
    return this.axios.delete('/api/products/' + id)
  }
  public static editOfferById(id: number, event: {}) {
    return this.axios.put('/api/products/' + id, event)
  }
  public static createOffer(event: {}) {
    return this.axios.post('/api/products', event)
  }
  public static changeStatusOffer(id: number, status: {}): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch('/api/products/status/' + id, status)
  }
  public static removeOfferImage(id: number) {
    return this.axios.delete(`/api/products/image/${id}`)
  }
  public static featureOffer(id: number) {
    return this.axios.patch(`/api/products/sort/${id}`)
  }
  public static unfeatureOffer() {
    return this.axios.delete(`/api/products/sort`)
  }

  public static getActivateOffer(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/products/activated?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  // Interactions
  public static getInteractions(type: string, page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/likes/${type}?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  // Audience
  public static fetchAudiences(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/audiences?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static createAudience(audience: {}) {
    return this.axios.post('/api/audiences', audience)
  }
  public static editAudienceById(id: number, event: {}) {
    return this.axios.put('/api/audiences/' + id, event)
  }
  public static deleteAudience(id: number) {
    return this.axios.delete('/api/audiences/' + id)
  }
  public static fetchAudiencesById(id: number) {
    return this.axios.get('/api/audiences/' + id)
  }
  public static realAudienceTimeCount(audience: {}) {
    return this.axios.post('/api/customers/realtime-audience', audience)
  }
  // Customers
  public static fetchCustomers(page: number = 1, perPage: number = 10, filter: string = '', sort: string = '') {
    return RestApi.axios.get(`/api/customers?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static fetchOneCustomer(id: any) {
    return RestApi.axios.get('/api/customers/' + id)
  }
  public static fetchCustomerTimeline(id: any, page: number, perPage: number, filter: string, sort: string) {
    return this.axios.get(`/api/customers/${id}/point-history?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getCustomerTimeline(id: number, page: number, perPage: number, filter: string) {
    return this.axios.get(`/api/customers/${id}/timeline?page=${page}&per_page=${perPage}${filter}`)
  }
  public static deleteCustomer(id: number) {
    return this.axios.delete('/api/customers/' + id)
  }
  public static changeCustomerStatus(id: number, status: number) {
    return this.axios.post(`/api/customers/${id}/change-status`, { status: status })
  }
  public static addCustomerPoints(id: number, points: number, reason: string | null) {
    return this.axios.post(`/api/customers/${id}/add-points`, { points: points, reason: reason })
  }
  public static getCustomerCount() {
    return this.axios.get(`/api/customers/count`)
  }
  public static getCustomersWithPhoneCount() {
    return this.axios.get(`/api/customers/phone-numbers/count`)
  }
  public static getAudienceWithPhoneCount(audienceId: number) {
    return this.axios.get(`/api/customers/audience/phone-numbers/count`, { params: { audienceId } })
  }
  // Export
  public static exportCustomers(filter: string) {
    return this.axios.get(`/api/exports/customers?${filter}`)
  }
  public static exportNews(filter: string) {
    return this.axios.get(`/api/exports/news?${filter}`)
  }
  public static exportEvents(filter: string) {
    return this.axios.get(`/api/exports/events?${filter}`)
  }
  public static exportProducts(filter: string) {
    return this.axios.get(`/api/exports/products?${filter}`)
  }
  public static exportOrganizations(filter: string) {
    return this.axios.get(`/api/exports/organizations?${filter}`)
  }
  public static exportReceipts(filter: string) {
    return this.axios.get(`/api/exports/receipts?${filter}`)
  }
  public static exportRewards(filter: string) {
    return this.axios.get(`/api/exports/rewards?${filter}`)
  }
  public static getExport(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/exports?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  // Report

  public static reportByType(type: string, filter: string) {
    return this.axios.get(`/api/report?period=custom&${filter}type=${type}`)
  }

  public static exportReportByTypeAndPeriod(type: string, filter: string) {
    return this.axios.get(`/api/exports/reports?${filter}period=custom&type=${type}`)
  }

  // TAGS
  public static syncCustomerTags(id: number, tags: number[]) {
    return this.axios.post(`/api/customers/${id}/sync-tags`, { tags: tags })
  }
  public static getTags(type: string, page: number = 1, perPage: number = 10, filter: string) {
    return this.axios.get('/api/tags?page=' + page + '&per_page=' + perPage + '&filter[type]=' + type + filter)
  }
  public static getAllTags(type: string = '', status: string | number = '') {
    return this.axios.get(`/api/tags?page=1&per_page=100&filter[status]=${status}&filter[type]=${type}`)
  }
  public static addTag(type: string, title: string, color: string) {
    const data = new FormData()
    data.set('title', title)
    data.set('color', color)
    data.set('type', type)
    return this.axios.post('/api/tags', data)
  }
  public static updateTag(id: number, title: string, color: string) {
    const data = new FormData()
    data.set('title', title)
    data.set('color', color)
    return this.axios.put(`/api/tags/${id}`, data)
  }
  public static updateTagColor(id: number, color: string) {
    const data = new FormData()
    data.set('color', color)
    return this.axios.put(`/api/tags/${id}`, data)
  }
  public static deactivateTag(id: number) {
    return this.axios.patch(`/api/tags/archive/${id}`)
  }
  public static activateTag(id: number) {
    return this.axios.put(`/api/tags/publish/${id}`)
  }

  // FILES
  public static getFiles(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/files?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static saveFloorplanImage(imageFile: File) {
    const data = new FormData()
    data.append('files[0]', imageFile)
    data.append('file_type[0]', 'image')

    return this.axios.post(`/api/files`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public static calculateFloorplanShortestPath(
    start: turf.helpers.Position,
    end: turf.helpers.Position,
    filteredFeatures: mapboxgl.MapboxGeoJSONFeature[],
    resolution: number
  ) {
    return this.axiosWebsiteInstance!.post(`/api/floorplan/shortestPath`, { start, end, filteredFeatures, resolution })
  }

  // TENANTS COMPANIES
  public static getAllTenantsList() {
    return this.axios.get(`/api/companies`)
  }

  public static getAllTenants(page: number = 1, perPage: number = 10, filter: string, sort: string = '') {
    return this.axios.get(`/api/companies?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static getTenant(id: number) {
    return RestApi.axios.get(`/api/companies/${id}`)
  }

  public static setStatusTenant(id: number, data: Object) {
    return this.axios.put(`/api/companies/status/${id}`, data)
  }

  public static createNewTenant(data: Object) {
    return this.axios.post('/api/companies', data)
  }

  public static updateTenant(id: number, data: Object) {
    return this.axios.put(`/api/companies/${id}`, data)
  }

  public static uploadTenantFoto(id: number, data: Object) {
    return this.axios.put(`/api/companies/${id}/upload-shop-photo`, data)
  }

  public static removeImage(id: number) {
    return this.axios.delete(`/api/companies/image/${id}`)
  }

  public static featureCompany(id: number) {
    return this.axios.post(`/api/companies/feature/${id}`)
  }

  public static unfeatureCompany(id: number) {
    return this.axios.post(`/api/companies/unfeature/${id}`)
  }

  public static unfeatureAllCompanies() {
    return this.axios.post(`/api/companies/unfeature-all`)
  }

  // Receipts
  public static getAllReceipts(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/receipts?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getOneReceipt(id: number) {
    return this.axios.get(`/api/receipts/${id}`)
  }
  public static getReceiptHistory(id: number) {
    return this.axios.get(`/api/receipts/receipt-history?receipt_id=${id}`)
  }
  public static getTemplates(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/receipts/template?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static inreviewReceipt(id: number, logData: LogData) {
    return this.axios.patch(`/api/receipts/to-review/${id}`, logData)
  }
  public static approveReceipt(id: number) {
    return this.axios.patch(`/api/receipts/approve/${id}`)
  }
  public static manualApproveReceipt(id: number, logData: LogData, payload: { rejection_reason: number[]; visual_error_codes: number[] }) {
    return this.axios.patch(`/api/receipts/manual-approve/${id}`, { ...logData, ...payload })
  }
  public static declineReceipt(id: number, reason: string, note: string) {
    const data = new FormData()
    data.set('rejection_reason', reason)
    data.set('description', note)
    return this.axios.patch(`/api/receipts/reject/${id}`, data)
  }
  public static manualDeclineReceipt(
    id: number,
    rejection_reason: number[],
    visual_error_codes: number[],
    new_visual_error: string,
    comment_for_customer: string | null,
    logData: LogData
  ) {
    const data = {
      rejection_reason,
      visual_error_codes,
      new_visual_error,
      comment_for_customer,
      ...logData
    }
    return this.axios.patch(`/api/receipts/manual-reject/${id}`, data)
  }
  public static declineReceipts(ids: string, rejection_reason: number[], visual_error_codes: number[], new_visual_error: string) {
    const data = {
      rejection_reason,
      visual_error_codes,
      new_visual_error
    }
    return this.axios.patch(`/api/receipts/manual-reject/ids/${ids}`, data)
  }
  public static approveReceipts(ids: string) {
    return this.axios.patch(`/api/receipts/approve/ids/${ids}`)
  }
  public static updateReceipt(id: number, data: Object, logData: LogData) {
    return this.axios.put(`/api/receipts/${id}`, { ...data, ...logData })
  }
  public static getReceiptSetup() {
    return this.axios.get(`/api/settings/loyalty`)
  }
  public static updateDailyLimitScan(data: number) {
    return this.axios.put(`/api/settings/loyalty/limit_daily_scan`, { value: data })
  }
  public static updateBlockReceipts(data: number) {
    return this.axios.put(`/api/settings/loyalty/block_receipts_before`, { value: data })
  }
  public static getSimilarReceipts(id: number) {
    return this.axios.get(`/api/similar-receipts/${id}`)
  }
  public static getRecognitionConstants() {
    return this.axios.get(`/api/receipts/constants`)
  }
  public static toggleErrorConfirmation(data: { admin_id: number; receipt_id: number; error_code: number; is_confirmed: boolean }) {
    return this.axios.patch('/api/receipts/error-confirmation', data)
  }
  public static pointsRecalculation(total_sum: string, company_id: number | null) {
    return this.axios.get('/api/receipts/points-recalculation', {
      params: {
        total_sum,
        company_id
      }
    })
  }

  public static updateBirthdayLoyalty(data: any) {
    return this.axios.put(`/api/settings/loyalty/birthday_loyalty`, data)
  }

  public static getBirthdayLoyalty() {
    return this.axios.get(`/api/settings/loyalty/birthday_loyalty`)
  }

  // Settings - general
  public static getCompanyProfile() {
    return this.axios.get('/api/company-profile')
  }
  public static getSettingsExtended() {
    return this.axios.get('/api/settings/extended ')
  }
  // Categories
  public static getAllCategories() {
    return this.axios.get('/api/company-cats?per_page=1000')
  }
  public static removeCategory(id: number) {
    return this.axios.delete(`/api/company-cats/${id}`)
  }
  public static createCategory(data: Object) {
    return this.axios.post(`/api/company-cats/`, data)
  }
  public static editCompanyCategory(id: number, data: Object) {
    return this.axios.put(`/api/company-cats/${id}`, data)
  }
  public static editCompanyCategoriesOrder(data: Object) {
    return this.axios.put(`/api/company-cats/order`, data)
  }
  // Organizations
  public static getOrganizations() {
    return this.axios.get('/api/organizations')
  }
  public static updateOrganization(id: string | number, data: any) {
    return this.axios.put(`/api/organizations/${id}`, data)
  }
  public static deleteOrganizationImage(image_id: string | number) {
    return this.axios.delete(`/api/organizations/image/${image_id}`)
  }
  public static createOrganization(data: any) {
    return this.axios.post('/api/organizations', data)
  }
  public static deleteOrganization(id: string | number) {
    return this.axios.delete(`/api/organizations/${id}`)
  }
  public static getOrganization(id: string | number) {
    return this.axios.get('/api/organizations/' + id)
  }
  public static getAllOrganizations(page: number = 1, perPage: number = 10, filter: string) {
    return this.axios.get(`/api/organizations?page=${page}&per_page=${perPage}${filter}`)
  }
  public static getContacts(filter: string) {
    return this.axios.get(`/api/contacts${filter}`)
  }
  // User (settings profile)
  public static getUserProfileAndPermissions() {
    return this.axios.get('/api/users/profile')
  }
  public static getAllUsers(name: string = '', tenant_id: number | string = '', pagination: { page: number; per_page: number }) {
    const { page, per_page } = pagination
    return this.axios.get(`/api/users?filter[name]=${name}&filter[company]=${tenant_id}&page=${page}&per_page=${per_page}`)
  }
  public static getUser(id: number) {
    return this.axios.get(`/api/users/${id}`)
  }
  public static createUser(data: Object) {
    return this.axios.post(`/api/users`, data)
  }
  public static updateUser(id: number, data: Object) {
    return this.axios.put(`/api/users/${id}`, data)
  }
  public static deleteUser(id: number) {
    return this.axios.delete(`/api/users/${id}`)
  }
  public static changePassword(id: number, oldPassword: string, newPassword: string, secondPassword: string) {
    const data = new FormData()
    data.set('old_password', oldPassword)
    data.set('password', newPassword)
    data.set('password_confirmation', secondPassword)
    return this.axios.put(`/api/users/${id}/password`, data)
  }
  public static getNotifications(id: number) {
    return this.axios.get(`/api/users/${id}/notifications`)
  }
  public static updateNotifications(id: number, notifications: object) {
    return this.axios.post(`/api/users/${id}/notifications`, notifications)
  }

  public static updateLang(lang: object) {
    return this.axios.put(`/api/users/language`, lang)
  }
  // Faq
  public static getAllFaqs(page: number = 1, perPage: number = 10, filters: string) {
    return this.axios.get(`/api/posts?filter[type]=faq&page=${page}&per_page=${perPage}&${filters}&filter[status]=1`)
  }
  public static createFaq(data: Object) {
    return this.axios.post(`/api/posts`, data)
  }
  public static updateFaq(id: number, data: Object) {
    return this.axios.put(`/api/posts/${id}`, data)
  }
  public static createFaqCategories(data: Object) {
    return this.axios.post('/api/post-categories', data)
  }

  public static editFaqCategoriesById(id: number, data: Object) {
    return this.axios.put(`/api/post-categories/${id}`, data)
  }

  public static getAllFaqCategories(page: number = 1, perPage: number = 10, filters: string) {
    return this.axios.get(`/api/post-categories?page=${page}&per_page=${perPage}&${filters}&filter[status]=1`)
  }

  public static deleteFaq(id: number) {
    return this.axios.delete(`/api/posts/${id}`)
  }
  public static deleteCategoryFaq(id: number) {
    return this.axios.put(`/api/post-categories/status/${id}`, { status: 0 })
  }
  // Tenants (Super Tenants)
  public static getAlltenants() {
    return this.axios.get(`/api/tenants`)
  }
  // Role
  public static getRoles() {
    return this.axios.get(`/api/roles`)
  }
  // rules
  public static getAllRules(page: number = 1, perPage: number = 10, filter: string) {
    return this.axios.get(`/api/settings/loyalty/points_to_czk/company?page=${page}&per_page=${perPage}${filter}`)
  }
  public static createLabelTemplate(id: number, data: Object) {
    return this.axios.patch(`/api/receipts/template/${id}`, data)
  }
  public static editLabelTemplate(id: number, data: Object) {
    return this.axios.put(`/api/receipts/template/status/${id}`, data)
  }
  // Malls
  public static getMalls() {
    return this.axios.get('/api/malls')
  }
  public static getSettingsLoyalty() {
    return this.axios.get('/api/settings/loyalty')
  }
  public static updateMalls(id: number, data: Object) {
    return this.axios.put(`/api/malls/${id}`, data)
  }
  public static updateMallsLimits(data: Object) {
    return this.axios.put(`/api/malls/limits`, data)
  }
  public static settingsLoyaltyModule(type: string, data: Object) {
    return this.axios.put(`/api/settings/loyalty/${type}`, data)
  }
  // Voucher
  public static postVoucherGroup(data: Object) {
    return this.axios.post('/api/voucher_groups', data)
  }
  public static postVoucher(data: Object) {
    return this.axios.post('/api/vouchers', data)
  }
  public static updateVoucherById(id: number, data: Object) {
    return this.axios.put(`/api/vouchers/${id}`, data)
  }
  public static getVouchers(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/voucher_groups?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getVoucherById(id: number) {
    return this.axios.get(`/api/voucher_groups/${id}`)
  }
  public static updateVoucherGroups(id: number, data: Object) {
    return this.axios.put(`/api/voucher_groups/${id}`, data)
  }
  public static deleteVoucherGroup(id: number) {
    return this.axios.put(`/api/voucher_groups/status/${id}`, { status: 0 })
  }
  public static changeStatusVoucherGroups(id: number, status: {}) {
    return this.axios.put('/api/voucher_groups/status/' + id, status)
  }
  public static changeStatusVouchers(id: number, status: {}) {
    return this.axios.put(`/api/vouchers/status/${id}`, status)
  }
  public static deleteVoucher(id: number) {
    return this.axios.put(`/api/vouchers/status/${id}`, { status: 0 })
  }
  public static getVoucheGroupsCustomersById(id: number, page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/voucher_groups/${id}/customers?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getVouchersActivatedCustomers(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/customer-vouchers?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getVouchersStatistics() {
    return this.axios.get(`/api/customer-vouchers/statistics`)
  }

  // Rewards Categories

  public static getRewardsCategoriesAll() {
    return this.axios.get(`/api/rewards-categories`)
  }

  public static createRewardsCategories(data: Object) {
    return this.axios.post(`/api/rewards-categories`, data)
  }

  public static editRewardsCategoriesId(id: number, data: Object) {
    return this.axios.put(`/api/rewards-categories/${id}`, data)
  }

  public static getRewardsCategoriesId(id: number) {
    return this.axios.get(`/api/rewards-categories/${id}`)
  }

  public static removeRewardsCategory(id: number) {
    return this.axios.delete(`/api/rewards-categories/${id}`)
  }

  public static removeRewardImage(id: number) {
    return this.axios.delete(`/api/rewards/image/${id}`)
  }

  public static addRemoveStockReward(id: number, data: object) {
    return this.axios.post(`/api/rewards/${id}/add-stock`, data)
  }

  public static featureRewards(id: number) {
    return this.axios.patch(`/api/rewards/sort/${id}`)
  }
  public static unfeatureRewards() {
    return this.axios.delete(`/api/rewards/sort`)
  }

  public static rewardsChangeStock(id: number, data: Object) {
    return this.axios.post(`/api/rewards/${id}/change-stock`, data)
  }

  // Tiers
  public static getTiers() {
    return this.axios.get(`/api/tiers`)
  }

  public static getTiersById(id: number) {
    return this.axios.get(`/api/tiers/${id}`)
  }

  public static createTiers(data: Object) {
    return this.axios.post(`/api/tiers`, data)
  }

  public static async updateTiers(data: Object) {
    try {
      return await this.axios.put(`/api/tiers`, data)
    } catch (error) {
      return error
    }
  }

  public static updateTiersLegacy(id: number, data: Object) {
    return this.axios.put(`/api/tiers/${id}`, data)
  }

  public static deleteTiers(id: number) {
    return this.axios.delete(`/api/tiers/${id}`)
  }
  // Amenities
  public static createAmenity(data: Object) {
    return this.axios.post(`/api/amenities`, data)
  }
  public static getAllAmenities(page: number = 1, perPage: number = 10, filter: string, sort: string) {
    return this.axios.get(`/api/amenities?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static getAmenityById(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/amenities/${id}`)
  }
  public static updateAmenity(id: number, data: Object) {
    return this.axios.put(`/api/amenities/${id}`, data)
  }
  public static setAmenityStatus(id: number, status: 0 | 1) {
    return this.axios.patch(`/api/amenities/change-status/${id}`, { status })
  }
  public static fetchAmenities(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/amenities?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static removeAmenityImage(id: number) {
    return this.axios.delete(`/api/amenities/image/${id}`)
  }

  public static featureAmenity(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.patch(`/api/amenities/sort/${id}`)
  }

  /*
   * Booking
   */

  // Services
  public static getServices(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/booking/services?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getServiceDetail(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get('/api/booking/services/' + id)
  }
  public static createService(data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.post('/api/booking/services/', data)
  }
  public static updateServiceId(id: number, data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.put('/api/booking/services/' + id, data)
  }

  // Options
  public static getOptions(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/booking/options?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getOptionDetail(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get('/api/booking/options/' + id)
  }
  public static createOption(data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.post('/api/booking/options/', data)
  }
  public static updateOptionId(id: number, data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.put('/api/booking/options/' + id, data)
  }

  // Orders
  public static getOrders(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/booking/orders?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
  public static getOrderDetail(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get('/api/booking/orders/' + id)
  }
  public static createOrder(data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.post('/api/booking/orders/', data)
  }
  public static updateOrderId(id: number, data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.put('/api/booking/orders/' + id, data)
  }

  /*
   * Tracking
   */

  // Links
  public static getTrackingLinks(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/deeplinks?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static createTrackingLink(data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.post('/api/deeplinks', data)
  }
  public static updateTrackingLinkId(id: number, data: Object): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.put('/api/deeplinks/' + id, data)
  }
  public static getSingleLink(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.get('/api/deeplinks/' + id)
  }
  public static deleteSingleLink(id: number): Promise<AxiosResponse<RestApiResponseItem>> {
    return this.axios.delete('/api/deeplinks/' + id)
  }

  // Terms & Conditions
  public static getTermsAndConditions(id: number | string) {
    return this.axios.get(`/api/malls/${id}/terms-and-conditions`)
  }
  public static updateTermsAndConditions(id: number | string, content: Object) {
    return this.axios.patch(`/api/malls/${id}/terms-and-conditions`, content)
  }
  public static removeCompanyProfileImage(id: number) {
    return this.axios.delete(`/api/malls/image/${id}`)
  }

  // Privacy Policy
  public static getPrivacyPolicy(id: number | string) {
    return this.axios.get(`/api/malls/${id}/privacy-policy`)
  }
  public static updatePrivacyPolicy(id: number | string, content: Object) {
    return this.axios.patch(`/api/malls/${id}/privacy-policy`, content)
  }

  // Cookies
  public static getCookies(id: number | string) {
    return this.axios.get(`/api/malls/${id}/cookies`)
  }
  public static updateCookies(id: number | string, content: Object) {
    return this.axios.patch(`/api/malls/${id}/cookies`, content)
  }

  // Operating rules
  public static getOperatingRules(id: number | string) {
    return this.axios.get(`/api/malls/${id}/operating-rules`)
  }
  public static updateOperatingRules(id: number | string, content: Object) {
    return this.axios.patch(`/api/malls/${id}/operating-rules`, content)
  }

  // Blogs

  public static getAllBlogs(page: number, per_page: number, filter: string, sort: string) {
    return this.axios.get(`/api/blogs?${filter}`, {
      params: {
        sort,
        per_page,
        page
      }
    })
  }
  public static getBlogById(id: number) {
    return this.axios.get(`/api/blogs/${id}`)
  }
  public static createBlog(blog: Partial<IBlog>) {
    return this.axios.post(`/api/blogs`, JSON.stringify(blog))
  }
  public static updateBlog(id: number, blog: Partial<IBlog>) {
    return this.axios.put(`/api/blogs`, JSON.stringify(blog))
  }
  public static deleteBlog(id: number) {
    return this.axios.delete(`/api/blogs/${id}`)
  }
  public static updateBlogStatus(id: number, status: 3 | 1) {
    return this.axios.patch(`/api/blogs/status`, {
      blog_id: id,
      status
    })
  }

  // Stories

  public static getAllStories(page: number, per_page: number, filter: string, sort: string) {
    return this.axios.get(`/api/stories?${filter}${sort}`, {
      params: {
        per_page,
        page,
        show_result: 0
      }
    })
  }
  public static getStoryById(id: number) {
    return this.axios.get(`/api/stories/${id}`)
  }
  public static createStory(blog: Partial<Story>) {
    return this.axios.post(`/api/stories`, JSON.stringify(blog))
  }
  public static updateStory(blog: Partial<Story>) {
    return this.axios.put(`/api/stories`, JSON.stringify(blog))
  }
  public static deleteStory(id: number) {
    return this.axios.delete(`/api/stories/${id}`)
  }
  public static updateStoryStatus(id: number, status: number) {
    return this.axios.patch(`/api/stories/status`, {
      story_id: id,
      status
    })
  }

  // Campaigns

  //In-app campaign
  public static getInAppCampaignOverview(query: string) {
    return this.axios.get(`/api/in-app-messages/overview/statistics?${query}`)
  }
  public static getInAppCampaignPerformance(query: string) {
    return this.axios.get(`/api/in-app-messages/overview/paginated-data?${query}`)
  }
  public static getInAppCampaignMessages(filter: string, page: number, sizePerPage: number) {
    return this.axios.get(`/api/in-app-messages?${filter}&page=${page}&per_page=${sizePerPage}`)
  }
  public static getInAppCampaignMessage(id: string | number) {
    return this.axios.get(`/api/in-app-messages/${id}`)
  }
  public static createInAppCampaignMessage(data: Partial<InAppMessage>) {
    return this.axios.post(`/api/in-app-messages`, data)
  }
  public static updateInAppCampaignMessage(data: Partial<InAppMessage>) {
    return this.axios.put(`/api/in-app-messages`, data)
  }
  public static deleteInAppCampaignMessage(id: string | number) {
    return this.axios.delete(`/api/in-app-messages/${id}`)
  }
  public static setInAppMessageStatus(status: number, id: string | number) {
    return this.axios.patch(`/api/in-app-messages/status`, { status, in_app_message_id: id })
  }

  // SMS campaign
  public static getSMSCampaignOverview(query: string) {
    return this.axios.get(`/api/sms/overview/statistics?${query}`)
  }
  public static getSMSCampaignPerformance(query: string) {
    return this.axios.get(`/api/sms/overview/paginated-data?${query}`)
  }
  public static getSMSCampaingMessages(filter: string, page: number, sizePerPage: number) {
    return this.axios.get(`/api/sms?${filter}&page=${page}&per_page=${sizePerPage}`)
  }
  public static getSMSCampaingMessage(id: string | number) {
    return this.axios.get(`/api/sms/${id}`)
  }
  public static updateSMSCampaignMessage(data: Partial<SMSMessage>) {
    return this.axios.put(`/api/sms`, data)
  }
  public static createSMSCampaignMessage(data: Partial<SMSMessage>) {
    return this.axios.post(`/api/sms`, data)
  }
  public static deleteSMSCampaingMessage(id: string | number) {
    return this.axios.delete(`/api/sms/${id}`)
  }
  public static setSMSMessageStatus(status: number, id: string | number) {
    return this.axios.patch(`/api/sms/status`, { status, sms_id: id })
  }

  // Loyalty campaign
  public static getLoyaltyCampaigns(query: string) {
    return this.axios.get(`/api/loyalty-campaigns${query}`)
  }
  public static getLoyaltyCampaign(id: string | number) {
    return this.axios.get(`/api/loyalty-campaigns/${id}`)
  }
  public static updateLoyaltyCampaign(data: LoyaltyCampaign) {
    return this.axios.put(`/api/loyalty-campaigns`, data)
  }
  public static createLoyaltyCampaign(data: LoyaltyCampaign) {
    return this.axios.post(`/api/loyalty-campaigns`, data)
  }
  public static setLoyaltyCampaignStatus(status: number, id: string | number) {
    return this.axios.patch(`/api/loyalty-campaigns/status`, { status, id })
  }

  // Logs
  public static getLogsRewards(page: number = 1, perPage: number = 10, query: string) {
    return this.axios.get(`/api/history/rewards?page=${page}&per_page=${perPage}&${query}`)
  }
  public static getLogsEvents(page: number = 1, perPage: number = 10, query: string) {
    return this.axios.get(`/api/history/events?page=${page}&per_page=${perPage}&${query}`)
  }
  public static getLogsNews(page: number = 1, perPage: number = 10, query: string) {
    return this.axios.get(`/api/history/news?page=${page}&per_page=${perPage}&${query}`)
  }
  public static getLogsProducts(page: number = 1, perPage: number = 10, query: string) {
    return this.axios.get(`/api/history/products?page=${page}&per_page=${perPage}&${query}`)
  }
  public static getLogsReceipts(page: number = 1, perPage: number = 10, query: string) {
    return this.axios.get(`/api/history/receipts?page=${page}&per_page=${perPage}&${query}`)
  }

  // Chat GPT
  public static getChatGptContent(content: {}) {
    return this.axios.post(`/api/ai-content-generator`, content)
    return this.axios.post(`/api/ai-content-generator`, content)
  }

  /*
   * Newsletter
   */

  public static createNewsletter(data: any) {
    return this.axios.post('/api/newsletters', data)
  }

  public static getNewsletters(query: string = '') {
    return this.axios.get(`/api/newsletters${query ? `?${query}` : ''}`)
  }

  public static getNewsletter(id: number) {
    return this.axios.get(`/api/newsletters/${id}`)
  }

  public static updateNewsletterStatus(status: number, id: number) {
    return this.axios.patch('/api/newsletters/status', { status, newsletter_id: id })
  }

  public static updateNewsletter(data: any) {
    return this.axios.put('/api/newsletters', data)
  }

  public static deleteNewsletter(id: string | number) {
    return this.axios.delete(`/api/newsletters/${id}`)
  }

  public static getNewslettersAudienceCount(id: number) {
    return this.axios.get(`/api/newsletters/subscribers/count?target_audience_id=${id}`)
  }

  public static getNewslettersSettings() {
    return this.axios.get('/api/newsletters/settings')
  }

  public static setNewslettersSettings(data: any) {
    return this.axios.post('/api/newsletters/settings', data)
  }

  // Template
  public static getAllTemplates(query: string = '', sort: string = '') {
    return this.axios.get(`/api/newsletters/templates${query ? `?${query}` : ''}${sort ? `&sort=${sort}` : ''}`)
  }

  public static getTemplate(id: number) {
    return this.axios.get(`/api/newsletters/templates/${id}`)
  }
  public static createTemplate(data: any) {
    return this.axios.post(`/api/newsletters/templates`, data)
  }

  public static updateTemplate(data: any) {
    return this.axios.put(`/api/newsletters/templates`, data)
  }

  public static setTemplateStatus(id: number, status: number) {
    return this.axios.patch(`/api/newsletters/templates/status`, { id, status })
  }

  public static deleteTemplate(id: number) {
    return this.axios.patch(`/api/newsletters/templates/status`, { status: 2, id: id })
  }

  public static publishTemplate(id: number) {
    return this.axios.patch(`/api/newsletters/templates/status`, { status: 1, id: id })
  }

  // Forms
  public static createForm(data: any) {
    return this.axios.post('/api/forms', data)
  }

  public static getForms(query: string = '') {
    return this.axios.get(`/api/forms${query ? `?${query}` : ''}`)
  }

  public static getForm(id: number) {
    return this.axios.get(`/api/forms/${id}`)
  }

  public static deleteForm(id: number) {
    return this.axios.delete(`/api/forms/${id}`)
  }

  public static getFormResponses(id: number) {
    return this.axios.get(`/api/forms/responses/${id}`)
  }

  public static getSubscribers(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/newsletters/subscribers?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static getTapgestCampaigns(query: string) {
    return this.axios.get(`/api/tapgest/campaigns${query}`)
  }
  public static getTapgestCampaign(id: string) {
    return this.axios.get(`/api/tapgest/campaigns/${id}`)
  }
  public static createTapgestCampaign(data: Campaign) {
    return this.axios.post(`/api/tapgest/campaigns`, data)
  }
  public static updateTapgestCampaign(id: string, data: Campaign) {
    return this.axios.put(`/api/tapgest/campaigns/${id}`, data)
  }
  public static updateSimplaqMediaDuration(media_simplaq_id: number, duration: number) {
    return this.axios.put(`/api/tapgest/campaigns/media/${media_simplaq_id}`, { duration })
  }
  public static updateTapgestMediaDuration(media_id: number, campaign_id: number, duration: number) {
    return this.axios.put(`/api/tapgest/campaigns/media/single/${media_id}/${campaign_id}`, { duration })
  }
  public static getDevicesTreeView() {
    return this.axios.get('/api/tapgest/campaigns/devices')
  }
  public static deleteSimplaqMedia(id: number | string) {
    return this.axios.delete(`/api/tapgest/campaigns/media/${id}`)
  }
  public static deleteTapgestMedia(media_id: number | string, campaign_id: number | string) {
    return this.axios.delete(`/api/tapgest/campaigns/media/single/${media_id}/${campaign_id}`)
  }
  public static getTapgestLocations(query: string) {
    return this.axios.get(`/api/tapgest/locations${query}`)
  }
  public static getTapgestLocation(id: number | string) {
    return this.axios.get(`/api/tapgest/locations/${id}`)
  }
  public static createTapgestLocation(data: any) {
    return this.axios.post(`/api/tapgest/locations`, data)
  }
  public static updateTapgestLocation(id: string, data: any) {
    return this.axios.put(`/api/tapgest/locations/${id}`, data)
  }
  public static getTapgestDevices(query: string) {
    return this.axios.get(`/api/tapgest/devices${query}`)
  }
  public static getTapgestDevice(id: number | string) {
    return this.axios.get(`/api/tapgest/devices/${id}`)
  }
  public static createTapgestDevice(data: any) {
    return this.axios.post(`/api/tapgest/devices`, data)
  }
  public static updateTapgestDevice(id: string, data: any) {
    return this.axios.put(`/api/tapgest/devices/${id}`, data)
  }

  // Galleries
  public static getGalleries(page: number = 1, perPage: number = 10, filter: string = '', sort: string = '') {
    return RestApi.axios.get(`/api/galleries?page=${page}&per_page=${perPage}${filter}${sort}`)
  }

  public static getGalleriesById(id: number) {
    return this.axios.get(`/api/galleries/${id}`)
  }

  public static createGallery(data: any) {
    return this.axios.post(`/api/galleries`, data)
  }

  public static updateGallery(id: number, data: any) {
    return this.axios.put(`/api/galleries/${id}`, data)
  }

  public static deleteGallery(id: number) {
    return this.axios.delete(`/api/galleries/${id}`)
  }

  public static publishGallery(id: number) {
    return this.axios.patch(`/api/galleries/change-status/${id}`, { status: 1 })
  }

  /**
   * Support
   */

  // Questions
  public static getSupportQuestions(
    page: number = 1,
    perPage: number = 10,
    filter: string = '',
    sort: string = ''
  ): Promise<AxiosResponse<RestApiResponseItem>> {
    return RestApi.axios.get(`/api/support?page=${page}&per_page=${perPage}${filter}${sort}`)
  }
}
