<template>
  <div class="second-sidebar">
    <h1 class="second-sidebar__title">
      {{ $t(category as string) }}
    </h1>
    <ul class="second-sidebar__nav">
      <li class="__item" v-for="(item, index) in secondaryMenu[category as keyof typeof secondaryMenu]" :key="index">
        <RouterLink :to="item.to" v-if="item.type === 'link' && item.permissions" class="second-sidebar__nav__link">
          <IconBase :name="item.ico"></IconBase>
          {{ $t(item.name) }}
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import IconBase from '@/components/icons/IconBase.vue'
import { onBeforeMount, computed } from 'vue'
import { useRoute } from 'vue-router'
import { secondaryMenu, filterMenu } from '@/controllers/MenuConfig'

const currentRoute = useRoute()
const category = computed(() => currentRoute.meta.category)

onBeforeMount(() => {
  filterMenu()
})
</script>
