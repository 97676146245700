<template>
  <v-form v-model="valid" @submit.prevent="submitForm()">
    <div class="input-wrapper">
      <v-text-field
        class="c-text-input"
        v-model="email"
        :rules="emailRules"
        :prepend-inner-icon="MailIcon"
        placeholder="name@example.com"
        :label="$t('email')"
        :dirty="true"
        :clearIcon="ClearIcon"
        clearable
      >
        <template v-slot:append-inner>
          <ValidIcon class="c-valid"></ValidIcon>
        </template>
      </v-text-field>
    </div>

    <div class="input-wrapper">
      <v-text-field
        v-model="password"
        :class="`c-text-input`"
        :prepend-inner-icon="PasswordIcon"
        :append-inner-icon="showPassword ? EyeIcon : EyeSlashIcon"
        :rules="passwordRull"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('password:')"
        :placeholder="$t('your_password')"
        :dirty="true"
        @click:append-inner="showPassword = !showPassword"
      >
        <template v-slot:append-inner>
          <ValidIcon class="c-valid"></ValidIcon>
        </template>
      </v-text-field>
    </div>
    <!-- Forgot password -->
    <!-- <div class="login-reset-link">
      <a href="#">Forgot password ?</a>
    </div> -->

    <div class="input-wrapper">
      <button :class="`submit-button ${valid ? '' : 'disabled'}`" type="submit" :disabled="!valid">Login</button>
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useLocale } from 'vuetify'
import ClearIcon from '@/components/icons/ClearIcon.vue'
import MailIcon from '@/components/icons/MailIcon.vue'
import ValidIcon from '@/components/icons/ValidIcon.vue'
import PasswordIcon from '@/components/icons/PasswordIcon.vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import EyeSlashIcon from '@/components/icons/EyeSlashIcon.vue'
import { RestApi } from '@/controllers/RestApi'
import { useAppStore } from '@/store/app'

const { t } = useLocale()
const appStore = useAppStore()
const passwordRull = [
  (value: string) => {
    if (value) return true
    return t('required_password')
  }
]
const emailRules = [
  (value: string) => {
    if (value) return true
    return t('e-mail_required.')
  },
  (value: string) => {
    if (/.+@.+\..+/.test(value)) return true
    return t('e-mail_valid.')
  }
]

const valid = ref()
const authStore = useAuthStore()
const showPassword = ref(false)
const email = ref('')
const password = ref('')
const formSubmitted = ref(false)
const router = useRouter()

async function submitForm() {
  formSubmitted.value = true

  if (valid.value) {
    try {
      const serverResponse = await RestApi.login(email.value, password.value)
      switch (serverResponse.data.httpCode) {
        case 400:
          if (localStorage.getItem('access_token')) {
            // already logged in
            router.push('/')
          }
          break
        case 200:
          // log in
          if (serverResponse.data.message == 'Invalid email or password.') {
            appStore.setSnackbar(true, {
              type: 'error',
              title: 'bad_password',
              text: null
            })
          } else {
            authStore.login(serverResponse.data)
            router.push('/')
          }
          break
        case 422:
          appStore.setSnackbar(true, {
            type: 'error',
            title: 'bad_password',
            text: null
          })
          break
        default:
          console.error('Error:', serverResponse.data)
          break
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
}
</script>
