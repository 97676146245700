<template>
  <div class="c-alert-holder" v-if="alerts">
    <div v-for="(alert, index) in alerts" :key="index" class="c-alert">
      <v-alert v-if="alert" transition="fade-transition" :class="alert.type" v-model="alert.active" rounded="8" closable>
        <template v-slot:prepend>
          <img :src="alertsIcons[alert.type]" class="alert-ico" :alt="alert.type" />
        </template>
        <template v-slot:close>
          <button>
            <img :src="alertsIcons.close" @click="alert.active = false" />
          </button>
        </template>
        <div class="title" v-if="alert.title">
          {{ $t(alert.title) }}
        </div>
        <div class="text" v-if="alert.text">{{ $t(alert.text) }}</div>
      </v-alert>
    </div>
  </div>
</template>
<script lang="ts" setup>
import alertsIcons from '@/assets/img/alerts/alertsIcons.vue'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
const appStore = useAppStore()
const { alerts } = storeToRefs(appStore)
</script>
