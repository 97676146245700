<template>
  <div class="profile-popup" v-click-outside="() => emits('open', false)">
    <div class="profile-popup__header user-info" @click="goToSettings()">
      <div class="user-info__avatar">
        <img v-if="authStore.user.picture" :src="authStore.user.picture" alt="avatar" />
        <div class="letters" v-if="!authStore.user.picture">
          {{ CustomerHelpers.createAvatar(authStore.user?.first_name as string, authStore.user?.last_name as string) }}
        </div>
      </div>
      <div class="profile-popup__header__avatar"></div>

      <div class="profile-popup__header__detail">
        <div class="name">{{ name }}</div>
        <div class="role">{{ authStore.authData?.role.name }}</div>
      </div>
    </div>
    <hr class="profile-popup__divider" />
    <hr class="profile-popup__divider" />

    <div class="profile-popup__item" @click="logout()">
      <div class="profile-popup__item__ico">
        <IconBase name="LogoutIcon" />
      </div>
      <div class="profile-popup__item__text">{{ $t('log_out') }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'
import { RestApi, Routes, CustomerHelpers } from '@/imports'
import IconBase from '@/components/icons/IconBase.vue'
const router = useRouter()
const authStore = useAuthStore()
const name = computed(() => {
  if (authStore.user.first_name && authStore.user.last_name) {
    return authStore.user.first_name + ' ' + authStore.user.last_name
  } else if (authStore.user.first_name && authStore.user.last_name == null) {
    return authStore.user.first_name
  } else if (authStore.user.last_name && authStore.user.first_name == null) {
    return authStore.user.last_name
  } else return 'N/A'
})
const emits = defineEmits(['open'])
function goToSettings() {
  router.push(Routes.SETTINGS_PROFILE)
  emits('open', false)
}
async function logout() {
  try {
    await RestApi.logout()
    authStore.logout()
    router.push(Routes.LOGIN)
  } catch (error) {
    console.error('Error:', error)
  }
}
</script>
