export class CustomerHelpers {
  public static createAvatar(firstName: string | null, lastName: string | null) {
    let avatarLetters = ''
    if (firstName) {
      avatarLetters = firstName.trim().charAt(0)
    }
    if (lastName) {
      avatarLetters += lastName.trim().charAt(0)
    }
    if (avatarLetters != '') {
      return avatarLetters
    } else return 'NA'
  }
}
