import { defineStore } from 'pinia'
import { IPropertyManagement, MallsInfo } from '@/imports'
import { checkformatTime } from '@/composebles/helpers'
export interface CompanyProfileStoreTypes {
  mall: MallsInfo
  property_management: IPropertyManagement
  opening_hours_description: Record<string, string | null>
  parking_description: Record<string, string | null>
  parking_price_list: any[]
  about_mall_description: Record<string, string | null>
}

export const useCompanyProfile = defineStore('companyProfile', {
  state: (): CompanyProfileStoreTypes => {
    return {
      mall: {
        id: 0,
        title: '',
        description: '',
        zip_code: '',
        country_id: null,
        city: '',
        address: '',
        account_name: '',
        property_type: '',
        location: '',
        utc: '',
        default_language: '',
        supported_languages: [],
        date_format: 'DD.MM.YYYY',
        time_format: 'HH:mm',
        opening_hours: null,
        management: null,
        company_logo: '',
        vat_number: '',
        legal_name: '',
        company_billing_id: '',
        registration_number: null,
        images: [],
        image: '',
        image_inverted: '',
        image_wide: '',
        image_wide_inverted: '',
        imagesTemp: [],
        translations: []
      },
      property_management: {
        company_legal_name: '',
        purchase_order_number: '',
        billing_email: '',
        registration_number: '',
        company_vat: '',
        billing_address: '',
        city: '',
        state: '',
        country: '',
        zip_code: ''
      },
      opening_hours_description: {},
      parking_description: {},
      parking_price_list: [],
      about_mall_description: {}
    }
  },
  actions: {
    transformOpeningHoursDescription() {
      for (const lang in this.opening_hours_description) {
        const indexedDataFetched = this.mall as { [key: `opening_hours_description:${string}`]: string }
        indexedDataFetched[`opening_hours_description:${lang}`] = this.opening_hours_description[lang] ?? 'unknown'
      }
    },
    transformParkingDescription() {
      for (const lang in this.parking_description) {
        const indexedDataFetched = this.mall as { [key: `parking_description:${string}`]: string }
        indexedDataFetched[`parking_description:${lang}`] = this.parking_description[lang] ?? 'unknown'
      }
    },
    setMall(mall: any) {
      this.mall = { ...mall }
      this.mall.date_format = mall?.date_format
      this.mall.time_format = checkformatTime(mall.time_format)
      this.mall.image = mall.image
      this.mall.image_inverted = mall.image_inverted
      this.mall.image_wide = mall.image_wide
      this.mall.image_wide_inverted = mall.image_wide_inverted
      if (mall?.property_management) {
        this.property_management = { ...mall?.property_management }
      }
    },
    setImages(image: any) {
      this.mall.imagesTemp.push(image)
    },
    setImageById(id: any, data: string) {
      this.mall.imagesTemp.forEach((el: any) => {
        if (el.id == id) {
          el.image = data
        }
      })
    },
    deleteImageById(id: any) {
      this.mall.imagesTemp.forEach((el: any, index: number) => {
        if (el.id == id) {
          this.mall.imagesTemp.splice(index, 1)
        }
      })
    },
    setOpeningHoursDescription(description: any) {
      this.opening_hours_description = description
    },
    setParkingDescription(description: any) {
      this.parking_description = description
    },
    setAboutMallDescription(description: any) {
      this.about_mall_description = description
    }
  }
})
