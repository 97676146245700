<template>
  <v-card>
    <v-app>
      <ButtonBase
        @click="toggleDrawer"
        icon="Arrowdown"
        class="navigation-drawer-toggle-button"
        :class="{ 'navigation-drawer-toggle-button--closed-click-to-open': isDrawerOpen === false }"
      />
      <v-navigation-drawer width="280" v-model="isDrawerOpen" :style="isDrawerOpen ? '' : 'left: 20px;'">
        <TheSidebars />
      </v-navigation-drawer>
      <v-main :style="isDrawerOpen ? '' : 'margin-left: 20px;'">
        <CustomAlert></CustomAlert>
        <CustomSnackbar></CustomSnackbar>
        <router-view />
        <v-overlay :model-value="loading" class="align-center justify-center overlay-custom" persistent>
          <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
      </v-main>
      <InfoGlobal v-if="infoGlobalId !== 0" :id="infoGlobalId" />
    </v-app>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TheSidebars from '@/components/ui/sidebar/TheSidebars.vue'
import CustomAlert from '@/components/ui/alerts/CustomAlert.vue'
import CustomSnackbar from '@/components/ui/alerts/CustomSnackbar.vue'
import InfoGlobal from '@/components/ui/common/InfoGlobal.vue'
import ButtonBase from '@/components/ui/button/ButtonBase.vue'
// import
import { useAppStore } from '@/store/app'
import { storeToRefs } from 'pinia'

const appStore = useAppStore()
const { loading, infoGlobalId } = storeToRefs(appStore)
const isDrawerOpen = ref<boolean>(true)

const toggleDrawer = (): void => {
  isDrawerOpen.value = !isDrawerOpen.value
}
</script>
<style lang="scss">
button.navigation-drawer-toggle-button {
  transition: left 0.2s ease;
  min-width: 0px;
  width: 20px;
  min-height: 0;
  height: 20px;
  z-index: 1100;
  border: 1px solid #eee;
  position: fixed;
  background: #ffffff;
  top: 70px;
  left: 268px;
  display: flex;
  padding: 2px;
  border-radius: 50%;
  &--closed-click-to-open {
    left: 8px;
    .c-icon {
      svg {
        transition: transform 1s ease;
        transform: rotate(270deg) !important;
        fill: #444 !important;
      }
    }
  }
  &:hover {
    background: $grey-lighter;
    border-color: $grey-lighter;
    .c-icon {
      svg {
        fill: #000;
      }
    }
  }
  .c-icon {
    svg {
      transform: rotate(90deg);
      fill: #ccc;
      width: 10px;
      height: 10px;
    }
  }
}
</style>
<style lang="scss">
.overlay-custom {
  .v-overlay__scrim {
    opacity: 0.5;
    transition: opacity 0.05s linear !important;
  }
}
</style>
